(function () {
    'use strict';
    angular
    .module("app")
    .controller('ActivitiesController', ActivitiesController);

    ActivitiesController.$inject = ['$scope', '$rootScope', 'ModelController', 'Activity'];

    function ActivitiesController($scope, $rootScope, ModelController, Activity) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;

        var options = {propertyName: "id", reverse: true, quantity: 50};
        var defaultActivity = {
            name: ""
        };
        var settings = {defaultModel: defaultActivity};

        $scope.Activities = new ModelController(Activity, options, settings);
        $rootScope.searchBox.link($scope.Activities.list);
    }
})();
