(function () {
    'use strict';
    angular
    .module("app")
    .controller('MunicipalitiesController', MunicipalitiesController);

    MunicipalitiesController.$inject = ['$scope', '$rootScope', 'ModelController', 'Municipality'];

    function MunicipalitiesController($scope, $rootScope, ModelController, Municipality) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;

        var options = {propertyName: "id", reverse: true, quantity: 50};
        var defaultModel = {
            name: ""
        };
        var settings = {
            defaultModel: defaultModel
        }

        $scope.Municipalities = new ModelController(Municipality, options, settings);
        $rootScope.searchBox.link($scope.Municipalities.list);
    }
})();
