(function () {
    'use strict';
    angular
    .module("app")
    .controller('TasksController', TasksController);

    TasksController.$inject = ['$scope', '$rootScope', '$q', 'ModelController', 'Modal', 'Task', 'TaskStudy', 'Contact', 'Study', 'Fem'];

    function TasksController($scope, $rootScope, $q, ModelController, Modal, Task, TaskStudy, Contact, Study, Fem) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;
        $scope.datepickerOptions = {
            format: 'dd/MM/yyyy',
            startingDay: 1,
            showWeeks: false
        }


        var searchOptions = {propertyName: "id", reverse: true, quantity: 50};
        var options = {propertyName: "id", reverse: true, quantity: 50, relations: "engineer employer studies fem_study fem_supervision"};
        var defaultModel = {
            "aa": "",
            "commit_date": "",
            "engineer_id": null,
            "engineer_name": "",
            "folder": "",
            "task_name": "",
            "employer_id": null,
            "employer_name": "",
            "task_position": "",
            "service_name": "",
            "protocol": "",
            "remarks": "",
            "comments": "",
            "study_fem": "",
            "fem_study_id": null,
            "supervision_fem": "",
            "fem_supervision_id": null,
            "payment": "",
            "payment_date": "",
            "task_description": "",
        };
        var settings = {
            defaultModel: defaultModel,
            dates: [
                "commit_date",
                "payment_date"
            ]
        }
        $scope.modal = new Modal();

        $scope.Tasks = new ModelController(Task, options, settings);
        $rootScope.searchBox.link($scope.Tasks.list);

        var taskStudySettings = {
            defaultModel: {
                "year": "",
                "engineer_id": null,
                "engineer_name": "",
                "task_id": null,
                "task_position": "",
                "task_name": "",
                "study_id": null,
                "study_name": "",
                "signature_id": null,
                "signature_name": "",
                "complete": 0,
                "delivery_date": "",
                "scholar_id": null,
                "scholar_name": "",
                "remarks": "",
            },
            dates: [
                "delivery_date"
            ]
        }
        $scope.TaskStudies = new ModelController(TaskStudy, searchOptions, taskStudySettings);

        $scope.TaskStudies.removeThenSplice = function (study, item, index) {
            var defer = $q.defer();
            $scope.TaskStudies.remove(study, defer.resolve);
            defer.promise.then(function (result) {
                if (result) {
                    item.studies.splice(index, 1);
                }
            });
        }
        var stagedStudy = null;
        $scope.TaskStudies.stageWithItem = function (study, item) {
            stagedStudy = item;
            $scope.TaskStudies.stage(study);
        }
        $scope.TaskStudies.saveStudyThenPush = function () {
            var study = angular.copy($scope.TaskStudies.modal.model);
            $scope.TaskStudies.modal.save().then(function (result) {
                if (result && stagedStudy) {
                    if (!stagedStudy.studies) {
                        stagedStudy.studies = [];
                    }
                    if (result === true) {
                        var index = stagedStudy.studies.map(function (e) {
                            return e.id;
                        }).indexOf(study.id);
                        if (index !== -1) {
                            stagedStudy.studies[index] = study;
                        } else {
                            console.warn("Study not found", study);
                        }
                    } else {
                        stagedStudy.studies.push(result);
                    }
                }
                stagedStudy = null;
            })
        }

        $scope.Studies = new ModelController(Study, searchOptions);
        $scope.Studies.onSelectStudy = function (item) {
            item = item || {};
            $scope.TaskStudies.modal.set("study_name", item.name);
            $scope.TaskStudies.modal.set("study", item);
        };
        $scope.Studies.onResetStudy = function() {
            $scope.TaskStudies.modal.model.study_id = null;
            $scope.TaskStudies.modal.model.study_name = ''
            delete $scope.TaskStudies.modal.model.study;
        };
        $scope.Studies.onSetStudy = function ($select) {
            delete $scope.TaskStudies.modal.model.study;
            $scope.TaskStudies.modal.model.study_id = null;
            $scope.TaskStudies.modal.set("study_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Contacts = new ModelController(Contact, searchOptions);
        $scope.Contacts.onSelectEngineer = function(item) {
            item = item || {};
            $scope.Tasks.modal.set("engineer_name", item.name);
            $scope.Tasks.modal.set("engineer", item);
        }
        $scope.Contacts.onResetEngineer = function() {
            $scope.Tasks.modal.model.engineer_id = null;
            $scope.Tasks.modal.model.engineer_name = ''
            delete $scope.Tasks.modal.model.engineer;
        };
        $scope.Contacts.onSetEngineer = function ($select) {
            delete $scope.Tasks.modal.model.engineer;
            $scope.Tasks.modal.model.engineer_id = null;
            $scope.Tasks.modal.set("engineer_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Contacts.onSelectEmployer = function(item) {
            item = item || {};
            $scope.Tasks.modal.set("employer_name", item.name);
            $scope.Tasks.modal.set("employer", item);
        }
        $scope.Contacts.onResetEmployer = function() {
            $scope.Tasks.modal.model.employer_id = null;
            $scope.Tasks.modal.model.employer_name = ''
            delete $scope.Tasks.modal.model.employer;
        };
        $scope.Contacts.onSetEmployer = function ($select) {
            delete $scope.Tasks.modal.model.employer;
            $scope.Tasks.modal.model.employer_id = null;
            $scope.Tasks.modal.set("employer_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Contacts.onSelectStudyEngineer = function(item) {
            item = item || {};
            $scope.TaskStudies.modal.set("engineer_name", item.name);
            $scope.TaskStudies.modal.set("engineer", item);
        }
        $scope.Contacts.onResetStudyEngineer = function() {
            $scope.TaskStudies.modal.model.engineer_id = null;
            $scope.TaskStudies.modal.model.engineer_name = ''
            delete $scope.TaskStudies.modal.model.engineer;
        };
        $scope.Contacts.onSetStudyEngineer = function ($select) {
            delete $scope.TaskStudies.modal.model.engineer;
            $scope.TaskStudies.modal.model.engineer_id = null;
            $scope.TaskStudies.modal.set("engineer_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Contacts.onSelectStudyScholar = function(item) {
            item = item || {};
            $scope.TaskStudies.modal.set("scholar_name", item.name);
            $scope.TaskStudies.modal.set("scholar", item);
        }
        $scope.Contacts.onResetStudyScholar = function() {
            $scope.TaskStudies.modal.model.scholar_id = null;
            $scope.TaskStudies.modal.model.scholar_name = ''
            delete $scope.TaskStudies.modal.model.scholar;
        };
        $scope.Contacts.onSetStudyScholar = function ($select) {
            delete $scope.TaskStudies.modal.model.scholar;
            $scope.TaskStudies.modal.model.scholar_id = null;
            $scope.TaskStudies.modal.set("scholar_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Contacts.onSelectStudySignature = function(item) {
            item = item || {};
            $scope.TaskStudies.modal.set("signature_name", item.name);
            $scope.TaskStudies.modal.set("signature", item);
        }
        $scope.Contacts.onResetStudySignature = function() {
            $scope.TaskStudies.modal.model.signature_id = null;
            $scope.TaskStudies.modal.model.signature_name = ''
            delete $scope.TaskStudies.modal.model.signature;
        };
        $scope.Contacts.onSetStudySignature = function ($select) {
            delete $scope.TaskStudies.modal.model.signature;
            $scope.TaskStudies.modal.model.signature_id = null;
            $scope.TaskStudies.modal.set("signature_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Fems = new ModelController(Fem, searchOptions);
        $scope.Fems.onSelectStudyFem = function (item) {
            item = item || {};
            $scope.Tasks.modal.set("study_fem", item.apy);
            $scope.Tasks.modal.set("fem_study", item);
        };
        $scope.Fems.onResetStudyFem = function() {
            $scope.Tasks.modal.model.fem_study_id = null;
            $scope.Tasks.modal.model.study_fem = ''
            delete $scope.Tasks.modal.model.fem_study;
        };
        $scope.Fems.onSetStudyFem = function ($select) {
            delete $scope.TaskStudies.modal.model.fem_study;
            $scope.Tasks.modal.model.fem_study_id = null;
            $scope.Tasks.modal.set("study_fem", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Fems.onSelectSupervisionFem = function (item) {
            item = item || {};
            $scope.Tasks.modal.set("supervision_fem", item.apy);
            $scope.Tasks.modal.set("fem_supervision", item);
        };
        $scope.Fems.onResetSupervisionFem = function() {
            $scope.Tasks.modal.model.fem_supervision_id = null;
            $scope.Tasks.modal.model.supervision_fem = ''
            delete $scope.Tasks.modal.model.fem_supervision;
        };
        $scope.Fems.onSetSupervisionFem = function ($select) {
            delete $scope.Tasks.modal.model.fem_supervision;
            $scope.Tasks.modal.model.fem_supervision_id = null;
            $scope.Tasks.modal.set("supervision_fem", $select.search);
            $select.search = "";
            $select.open = false;
        };

    }
})();
