(function() {
	'use strict';
	angular
	.module("app")
	.controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$rootScope', '$http', '$localStorage', 'Paging', 'ModelController', 'Modal', 'Protocol'];

    function HomeController($scope, $rootScope, $http, $localStorage, Paging, ModelController, Modal, Protocol) {
        $rootScope.searchBox.link(new Paging([]));

        $scope.Protocols    = new ModelController(Protocol);
        $scope.modal = new Modal();

        $scope.protocols = {
            filters: $localStorage.expiring,
            list: [],
            fetch: function () {
                var parent = this;
                $scope.Protocols.resource.expiring(this.filters).$promise.then(function (data) {
                    var key = data.key;
                    parent.list = data[key];
                });
            }
        }
    }
})();
