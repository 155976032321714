(function () {
    'use strict';
    angular
    .module("app")
    .controller('UsersController', UsersController);

    UsersController.$inject = ['$scope', '$rootScope', 'ModelController', 'User', 'Role'];

    function UsersController($scope, $rootScope, ModelController, User, Role) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;

        var options = {propertyName: "id", reverse: true, quantity: 50, relations: "roles"};
        var defaultModel = {
            name: ""
        };
        var settings = {
            defaultModel: defaultModel,
            dates: ["updated_at", "created_at", "email_verified_at"]
        }

        $scope.Users = new ModelController(User, options, settings);
        $rootScope.searchBox.link($scope.Users.list);

        var searchOptions = {propertyName: "id", reverse: true, quantity: 50};
        $scope.Roles = new ModelController(Role, searchOptions);
        $scope.Roles.onSelect = function(item) {
            if (item) {
                $scope.Users.modal.set("roles", [item]);
                $scope.Users.modal.set("role", item.name);
            } else {
                $scope.Users.modal.set("roles", []);
                $scope.Users.modal.set("role", '');
            }
        }
        $scope.Roles.onReset = function() {
            $scope.Users.modal.model.roles = [];
            $scope.Users.modal.model.role = ''
        };
    }
})();
