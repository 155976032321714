(function () {
    'use strict';
    angular
    .module("app")
    .controller('TaskStudiesController', TaskStudiesController);

    TaskStudiesController.$inject = ['$scope', '$rootScope', '$q', 'ModelController', 'Modal', 'Task', 'TaskStudy', 'Contact', 'Study'];

    function TaskStudiesController($scope, $rootScope, $q, ModelController, Modal, Task, TaskStudy, Contact, Study) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;
        $scope.datepickerOptions = {
            format: 'dd/MM/yyyy',
            startingDay: 1,
            showWeeks: false
        }


        var searchOptions = {propertyName: "id", reverse: true, quantity: 50};
        var options = {propertyName: "id", reverse: true, quantity: 50, relations: "task engineer scholar signature study"};
        var defaultModel = {
            "year": "",
            "engineer_id": null,
            "engineer_name": "",
            "task_id": null,
            "task_position": "",
            "task_name": "",
            "study_id": null,
            "study_name": "",
            "signature_id": null,
            "signature_name": "",
            "complete": 0,
            "delivery_date": "",
            "scholar_id": null,
            "scholar_name": "",
            "remarks": "",
        };
        var settings = {
            defaultModel: defaultModel,
            dates: [
                "delivery_date"
            ]
        }
        $scope.modal = new Modal();

        $scope.TaskStudies = new ModelController(TaskStudy, options, settings);
        $rootScope.searchBox.link($scope.TaskStudies.list);

        $scope.Tasks = new ModelController(Task, searchOptions);
        $scope.Tasks.onSelect = function (item) {
            item = item || {};
            $scope.TaskStudies.modal.set("task_name", item.task_name);
            $scope.TaskStudies.modal.set("task_position", item.task_position);
            $scope.TaskStudies.modal.set("task", item);
        }
        $scope.Tasks.onSetTask = function ($select) {
            delete $scope.TaskStudies.modal.model.task;
            $scope.TaskStudies.modal.model.task_id = null;
            $scope.TaskStudies.modal.model.task_name = $select.search;
            $scope.TaskStudies.modal.model.task_position = '';
        }
        $scope.Tasks.onReset = function () {
            $scope.TaskStudies.modal.model.task_id = null;
            $scope.TaskStudies.modal.model.task_name = '';
            $scope.TaskStudies.modal.model.task_position = '';
            delete $scope.TaskStudies.modal.model.task;
        }


        $scope.Studies = new ModelController(Study, searchOptions);
        $scope.Studies.onSelectStudy = function (item) {
            item = item || {};
            $scope.TaskStudies.modal.set("study_name", item.name);
            $scope.TaskStudies.modal.set("study", item);
        };
        $scope.Studies.onResetStudy = function() {
            $scope.TaskStudies.modal.model.study_id = null;
            $scope.TaskStudies.modal.model.study_name = '';
            delete $scope.TaskStudies.modal.model.study;
        };
        $scope.Studies.onSetStudy = function ($select) {
            delete $scope.TaskStudies.modal.model.study;
            $scope.TaskStudies.modal.model.study_id = null;
            $scope.TaskStudies.modal.set("study_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Contacts = new ModelController(Contact, searchOptions);

        $scope.Contacts.onSelectStudyEngineer = function(item) {
            item = item || {};
            $scope.TaskStudies.modal.set("engineer_name", item.name);
            $scope.TaskStudies.modal.set("engineer", item);
        }
        $scope.Contacts.onResetStudyEngineer = function() {
            $scope.TaskStudies.modal.model.engineer_id = null;
            $scope.TaskStudies.modal.model.engineer_name = ''
            delete $scope.TaskStudies.modal.model.engineer;
        };
        $scope.Contacts.onSetStudyEngineer = function ($select) {
            delete $scope.TaskStudies.modal.model.engineer;
            $scope.TaskStudies.modal.model.engineer_id = null;
            $scope.TaskStudies.modal.set("engineer_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Contacts.onSelectStudyScholar = function(item) {
            item = item || {};
            $scope.TaskStudies.modal.set("scholar_name", item.name);
            $scope.TaskStudies.modal.set("scholar", item);
        }
        $scope.Contacts.onResetStudyScholar = function() {
            $scope.TaskStudies.modal.model.scholar_id = null;
            $scope.TaskStudies.modal.model.scholar_name = ''
            delete $scope.TaskStudies.modal.model.scholar;
        };
        $scope.Contacts.onSetStudyScholar = function ($select) {
            delete $scope.TaskStudies.modal.model.scholar;
            $scope.TaskStudies.modal.model.scholar_id = null;
            $scope.TaskStudies.modal.set("scholar_name", $select.search);
            $select.search = "";
            $select.open = false;
        };

        $scope.Contacts.onSelectStudySignature = function(item) {
            item = item || {};
            $scope.TaskStudies.modal.set("signature_name", item.name);
            $scope.TaskStudies.modal.set("signature", item);
        }
        $scope.Contacts.onResetStudySignature = function() {
            $scope.TaskStudies.modal.model.signature_id = null;
            $scope.TaskStudies.modal.model.signature_name = ''
            delete $scope.TaskStudies.modal.model.signature;
        };
        $scope.Contacts.onSetStudySignature = function ($select) {
            delete $scope.TaskStudies.modal.model.signature;
            $scope.TaskStudies.modal.model.signature_id = null;
            $scope.TaskStudies.modal.set("signature_name", $select.search);
            $select.search = "";
            $select.open = false;
        };
    }
})();
