(function() {
	'use strict';
	angular
	.module("app")
	.controller('AdminController', AdminController);

	AdminController.$inject = ['$rootScope', '$state', '$transitions', '$q', '$location', 'Paging', '$http', 'Auth', 'currentAuth'];

	function AdminController($rootScope, $state, $transitions, $q, $location, Paging, $http, Auth, currentAuth) {
		$rootScope.jQueryReady();
		var vm = this;
        vm.user = currentAuth;
		var d = new Date();
        vm.currentYear = d.getFullYear();
        vm.menuItems = [
			{"icon" : "fa-home", "href": 'admin', "label": { "en": 'Home', "el": 'Αρχική'}, "active": true, "module": "admin.home"},
            {"icon" : "fa-vcard-o", "href": 'admin/contacts', "label": { "en": 'Contacts', "el": 'Επαφές'}, "active": Auth.isAdmin(), "module": "admin.contacts"},
            {"icon" : "fa-handshake-o", "href": 'admin/customers', "label": { "en": 'Customers', "el": 'Πελάτες'}, "active": Auth.isAdmin(), "module": "admin.customers"},
            {"icon" : "fa-contao", "href": 'admin/customers-chris', "label": { "en": 'Customers of Chris', "el": 'Πελάτες Χρήστου'}, "active": Auth.isAdmin(), "module": "admin.customersChris"},
            {"icon" : "fa-gavel", "href": 'admin/protocols', "label": { "en": 'Protocols', "el": 'Πρωτόκολλο'}, "active": Auth.isAdmin(), "module": "admin.protocols"},
			{"icon" : "fa-money", "href": 'admin/fem', "label": { "en": 'FEM', "el": 'Τιμολόγια'}, "active": Auth.isAdmin(), "module": "admin.fem"},
			{"icon" : "fa-tasks", "href": 'admin/tasks', "label": { "en": 'Tasks', "el": 'Πολεοδομία'}, "active": Auth.isAdmin(), "module": "admin.tasks"},
			{"icon" : "fa-book", "href": 'admin/task-studies', "label": { "en": 'Task Studies', "el": 'Μελέτες'}, "active": Auth.isAdmin(), "module": "admin.taskStudies"},
			{"icon" : "",  "href": 'admin/404', "label": { "en": '404 - Not Found', "el": '404 - Η σελίδα δεν βρέθηκε'}, "active": false, "module": "admin.404" },
            {
                "icon" : "fa-cog",
                "label": { "en": 'Settings', "el": 'Ρυθμίσεις'},
                active: true,
                collapsed: true,
                sub: [
                    {"icon" : "fa-users", "href": 'admin/users', "label": { "en": 'Users', "el": 'Χρήστες'}, "active": Auth.isAdmin(), "module": "admin.users"},
                    {"icon" : "fa-id-badge", "href": 'admin/afm', "label": { "en": 'Tax ID', "el": 'Α.Φ.Μ.'}, "active": Auth.isAdmin(), "module": "admin.afm"},
                    {"icon" : "fa-file-word-o", "href": 'admin/templates', "label": { "en": 'Word Template', "el": 'Πρότυπα Word'}, "active": Auth.isAdmin(), "module": "admin.templates"},
                    {"icon" : "fa-building", "href": 'admin/services', "label": { "en": 'Services', "el": 'Υπηρεσίες'}, "active": true, "module": "admin.services"},
                    {"icon" : "fa-university", "href": 'admin/doys', "label": { "en": 'Doy', "el": 'Δ.Ο.Υ.'}, "active": true, "module": "admin.doys"},
                    {"icon" : "fa-file-text", "href": 'admin/studies', "label": { "en": 'Studies', "el": 'Τύποι Μελετών'}, "active": true, "module": "admin.studies"},
                    {"icon" : "fa-certificate", "href": 'admin/activities', "label": { "en": 'Activities', "el": 'Δραστηριότητες'}, "active": true, "module": "admin.activities"},
                    {"icon" : "fa-map-marker", "href": 'admin/municipalities', "label": { "en": 'Municipalities', "el": 'Δήμοι'}, "active": true, "module": "admin.municipalities"},
                    {"icon" : "fa-bug", "href": 'admin/logs', "label": { "en": 'Logs', "el": 'Αρχεία Καταγραφής'}, "active": Auth.isAdmin(), "module": "admin.logs", permission: "view logs"}
                ],
                module: "admin.sub"
            }
		];
        this.can = function (menuItem) {
            return menuItem.active && (
                    Auth.isSuperAdmin() ||
                    !menuItem.permission ||
                    Auth.user.permissions.includes(menuItem.permission)
                );
        };
        this.isAllowedTo = function (permission) {
            return Auth.isSuperAdmin() || Auth.user.permissions.includes(permission)
        }
        this.menuClick = function (menuItem) {
            if ($(window).outerWidth() < 1183) {
                $('#toggle-btn').trigger('click');
            }
        };
        this.init = function(){
			var module = $state.current.module || $rootScope.mainState;
			this.currentPage = this.getCurrentPage(module);
		};
        this.getCurrentPage = function(module){
            var items = {};
            this.menuItems.map(function (item){
                if (item.sub) {
                    item.sub.map(function(sub){
                        items[sub.module] = sub;
                    })
                } else {
                    items[item.module] = item;
                }
            });
			return items[module] ? items[module] : items[$rootScope.mainState];
		};
		this.logout = function () {
            Auth.logout().then(function () {
                $state.go("login");
            });
        };
		function htmlDecode(value) {
			return $("<textarea/>").html(value).text();
		}
		function htmlEncode(value) {
			return $('<textarea/>').text(value).html();
		}
		this._ = function(translation, decode){
			if(!translation)
				return "";
			var txt = translation[$rootScope.lang] ? translation[$rootScope.lang] : (angular.isDefined(translation['el']) ? translation['el'] : translation);
			if(angular.isDefined(decode)){
				txt = htmlDecode(txt);
			}
			return txt;
		};

		var translations = {
			"Find" : "Εύρεση",
			"New Product" : "Νέο Είδος",
			"Product" : "Προϊόν",
			"Category" : "Κατηγορία",
			"Total Quantity" : "Συνολική Ποσότητα",
			"Update Date" : "Ημ/νία Ενημέρωσης",
		};
		this.__ = function(text){
			if($rootScope.lang !== "en" && translations[text]){
				return translations[text];
			}
			return text;
		};

		this.actions = {
			delete : function(collection, id){
				if(!confirm("Είσαι σίγουρος/η ότι θές να διαγράψεις αυτήν την καταχώρηση? Η ενέργεια είναι μη αναστρέψιμη!")){
					return;
				}
				delete collection[id];
			},
			arrayDown : function (arr, index) {
				arr.splice(index + 1, 0, arr.splice(index, 1)[0]);
			},
			arrayUp : function (arr, index) {
				arr.splice(index - 1, 0, arr.splice(index, 1)[0]);
			}
		};

		this.helpers = {
			getDate: function(str){
				var d = new Date(str);
				return d.present();
			}
		};
	}

})();
