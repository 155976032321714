(function() {
	'use strict';
	angular
	.module("app")
	.controller('LogsController', LogsController);

    LogsController.$inject = ['$scope', '$rootScope', '$http', 'Paging', '$localStorage'];

    function LogsController($scope, $rootScope, $http, Paging, $localStorage) {
        var options = $localStorage.$default({
            logs: {
                current_file: null,
                current_folder: null,
                controls: {
                    propertyName: "date",
                    quantity: 10,
                    reverse: true
                }
            }
        });
        $scope.$logs = new Paging([], options.logs.controls);
        options.logs.controls = $scope.$logs.controls;
        $rootScope.searchBox.link($scope.$logs);

        $scope.$folders = [];
        $scope.$current_folder = null;
        $scope.$folder_files = [];
        $scope.$files = [];
        $scope.$current_file = null;
        $scope.$standardFormat = true;

        $scope.control = {
            init: function () {
                this.getLogs(options.logs.current_file, options.logs.current_folder);
            },
            handleData: function(data) {
                options.logs.current_folder = data.current_folder;
                options.logs.current_file = data.current_file;
                $scope.$logs.setCollection(data.logs).setPage(0);
                $scope.$folders = data.folders;
                $scope.$current_folder = data.current_folder;
                $scope.$folder_files = data.folder_files;
                $scope.$files = data.files;
                $scope.$current_file = data.current_file;
                $scope.$standardFormat = data.standardFormat;
            },
            getLogs: function (file, folder) {
                var parent = this;
                var config = {
                    method: "GET",
                    url: "/api/logs",
                    params: {}
                };
                if (file) {
                    config.params.l = file;
                }
                if (folder) {
                    config.params.f = folder;
                }
                $http(config)
                .then(function (response) {
                    if (response.data.success === false) {
                        alertify.error(response.data.reason);
                        return;
                    }
                    parent.handleData(response.data);
                })
                .catch(function (err) {
                    console.warn(err);
                })
            },
            download: function (file, folder) {
                var config = {
                    method: "GET",
                    url: "/api/logs",
                    params: {
                        dl: file
                    }
                };
                if (folder) {
                    config.params.f = folder;
                }

                $http(config).then(function (response) {
                    if (!response.data) {
                        alertify.error("Αποτυχία");
                        return;
                    }
                    try {
                        var blob = new Blob([response.data], {type: "text/plain;charset=utf-8"});
                        saveAs(blob, file);
                    } catch (e) {
                        alertify.error("Αποτυχία!");
                    }
                })
            },
            action: function (action, file, folder) {
                var parent = this;
                alertify.confirm("Είστε σίγουρος/η?", function () {
                    var config = {
                        method: "GET",
                        url: "/api/logs",
                        params: {
                            [action]: file
                        }
                    };
                    if (folder) {
                        config.params.f = folder;
                    }

                    $http(config).then(function (response) {
                        console.log(response);
                        if (!response.data) {
                            alertify.error("Αποτυχία");
                            return;
                        }
                        alertify.success("Επιτυχία");
                        parent.handleData(response.data);
                    })

                });
            }
        };
    }
})();
