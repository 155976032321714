(function () {
    'use strict';
    angular
    .module("app")
    .controller('ProtocolsController', ProtocolsController);

    ProtocolsController.$inject = ['$scope', '$rootScope', '$q', 'currentAuth', 'TypeAhead', 'ModelController', 'Modal', 'Protocol', 'Customer', 'Study', 'Service', 'singleProtocol'];

    function ProtocolsController($scope, $rootScope, $q, currentAuth, TypeAhead, ModelController, Modal, Protocol, Customer, Study, Service, singleProtocol) {
        var options         = {propertyName: "id", reverse: true, quantity: 50, relations: "customer"};
        var defaultProtocol = {
            "customer_id"     : "",
            "customer_name"   : "",
            "employee_id"     : null,
            "employee_name"   : "",
            "study_type"      : "",
            "complete"        : 0,
            "application_date": "",
            "protocol"        : "",
            "service_id"      : null,
            "service_name"    : "",
            "service_employee": "",
            "comment"         : "",
            "fee"             : 0,
            "autopsy_date"    : null,
            "completed_at"    : null,
            "expiry_date"     : null
        };
        var settings = {
            defaultModel: defaultProtocol,
            dates: [
                "autopsy_date",
                "completed_at",
                "expiry_date",
                "application_date"
            ]
        }

        $scope.Protocols    = new ModelController(Protocol, options, settings);
        $scope.searchEnabled = $(window).outerHeight() >= 720;

        $rootScope.searchBox.link($scope.Protocols.list);

        $scope.modal = new Modal();

        $scope.singleProtocol = {
            id: singleProtocol,
            checked: false,
            model: null,
            check: function () {
                if (!this.id) {
                    return;
                }
                var parent = this;
                $scope.Protocols.findOne({id: this.id}).then(function (response) {
                    if (response.success) {
                        parent.model = response[response.key];
                    } else {
                        alertify.warning("Δεν βρέθηκε");
                    }
                })
                .catch(function (err) {
                    console.warn(err);
                    alertify.error("Προέκυψε κάποιο σφάλμα επικοινωνίας με τον Server");
                }).then(function () {
                    parent.checked = true;
                })
            }
        };

        var searchOptions         = {propertyName: "id", reverse: true, quantity: 50};

        $scope.Customers = new ModelController(Customer, searchOptions);
        $scope.Customers.onSelect = function(item) {
            item = item || {};
            $scope.Protocols.modal.set("customer_name", item.name);
        }
        $scope.Customers.onReset = function() {
            $scope.Protocols.modal.model.customer_id = null;
            $scope.Protocols.modal.model.customer_name = ''
        };

        $scope.Studies = new ModelController(Study, searchOptions);
        $scope.Studies.onSelect = function(item) {
            item = item || {};
            $scope.Protocols.modal.set("study_type", item.name);
        }
        $scope.Studies.onReset = function() {
            $scope.Protocols.modal.model.study_id = null;
            $scope.Protocols.modal.model.study_type = ''
        };


        $scope.Services = new ModelController(Service, searchOptions);
        $scope.Services.onSelect = function(item) {
            item = item || {};
            $scope.Protocols.modal.set("service_name", item.name);
        }
        $scope.Services.onReset = function() {
            $scope.Protocols.modal.model.service_id = null;
            $scope.Protocols.modal.model.service_name = ''
        };

        $scope.typeAhead = new TypeAhead($scope.Protocols);
        $scope.typeAhead.registerResource("customer_id",  Customer);
        $scope.typeAhead.registerResource("study_type",  Study);
        $scope.typeAhead.registerResource("service_name",  Service);

        $scope.datepickerOptions = {
            format: 'dd/MM/yyyy',
            startingDay: 1,
            showWeeks: false
        }

        $scope.singleProtocol.check();
    }
})();
