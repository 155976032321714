(function () {
    'use strict';

    angular
    .module("app")
    .factory("Auth", Auth)
    .factory("HttpInterceptor", HttpInterceptor)
    .factory("Paging", Paging)
    .factory("Customer", Customer)
    .factory("CustomerChris", CustomerChris)
    .factory("Protocol", Protocol)
    .factory("Modal", Modal)
    .factory("Toast", Toast)
    .factory("TypeAhead", TypeAhead)
    .factory("ModelController", ModelController)
    .factory("Activity", Activity)
    .factory("Study", Study)
    .factory("Municipality", Municipality)
    .factory("Contact", Contact)
    .factory("Service", Service)
    .factory("Doy", Doy)
    .factory("Fem", Fem)
    .factory("Task", Task)
    .factory("TaskStudy", TaskStudy)
    .factory("User", User)
    .factory("Role", Role);

    HttpInterceptor.$inject = ["$rootScope", "$q", "$localStorage"];

    function HttpInterceptor($rootScope, $q) {
        return {
            request      : function (config) {
                if ($rootScope.debug) {
                    config.headers            = config.headers || {};
                    config.headers["x-debug"] = true;
                }
                return config;
            },
            requestError : function (rejection) {
                // your error handler
                console.log(rejection);
            },
            response     : function (response) {
                return response || $q.when(response);
            },
            responseError: function (rejection) {
                // your error handler
                if (rejection.status === 403) {
                    $rootScope.$broadcast("Auth", ["signOut"]);
                }
                return rejection;
            }
        }
    }

    Auth.$inject = ["$http", "$q", "$localStorage"];

    function Auth($http, $q, $localStorage) {

        var basicUser = {
            id         : 0,
            name       : "",
            photo      : "",
            role       : "basic",
            permissions: []
        };
        return {
            auth              : $localStorage.auth,
            token             : null,
            notify            : false,
            endpoint          : null,
            user              : {
                id         : 0,
                name       : "",
                photo      : "",
                role       : "basic",
                permissions: [],
                isAdmin    : false,
                isSuperAdmin: false
            },
            setUser           : function (userObject) {
                $localStorage.auth.id = userObject.id;
                var user              = angular.copy(userObject);
                var role              = "basic";
                if (userObject.roles && userObject.roles.length) {
                    role = userObject.roles[0].name;
                }
                this.user.id          = user.id;
                this.user.name        = user.name;
                this.user.photo       = user.photo || user.gravatar;
                this.user.role        = role;
                this.user.permissions = user.permissions || [];
                this.user.isAdmin     = this.isAdmin();
                this.user.isSuperAdmin= this.isSuperAdmin();
            },
            register          : function (name, email, password, password_confirmation) {
                var parent = this;
                return $http({
                    method: "POST",
                    url   : "/api/register",
                    data  : {
                        name                 : name,
                        email                : email,
                        password             : password,
                        password_confirmation: password_confirmation
                    }
                })
                .then(function (response) {
                    if (response.data.success && response.data.user) {
                        parent.setUser(response.data.user);
                        return parent.user;
                    }
                    return $q.reject(response.data.reason);
                });
            },
            login             : function (email, password, remember_me) {
                var parent = this;
                return $http({
                    method: "POST",
                    url   : "/api/login",
                    data  : {
                        email   : email,
                        password: password,
                        remember: !!remember_me
                    }
                })
                .then(function (response) {
                    if (response.data.success && response.data.user) {
                        parent.setUser(response.data.user);
                        return parent.user;
                    }
                    return $q.reject(response.data.reason);
                })
            },
            setToken          : function (token, notify) {
                this.token = token;
                this.notify = !!notify;
                if (!token) {
                    return false;
                }
                if ($localStorage.token && $localStorage.token === token) {
                    return true;
                }
                if (this.check()) {
                    return this.sendTokenToServer();
                }
                return false;
            },
            sendTokenToServer : function() {
                if (!this.token) {
                    return false;
                }
                var token = this.token;
                return $http({
                    method: "POST",
                    url   : "/api/token",
                    data  : {
                        token: token,
                        notify: !!this.notify
                    }
                })
                .then(function (response) {
                    if (!response.data.success) {
                        return false;
                    }
                    $localStorage.token = token;
                    return true;
                })
                .catch(function (err) {
                    console.error(err);
                    return false;
                })
            },
            check             : function () {
                return this.user.id;
            },
            isAdmin           : function () {
                return this.user.role === "admin" || this.user.role === "superuser";
            },
            isSuperAdmin      : function () {
                return this.user.role === "superuser";
            },
            getUser           : function () {
                if (this.user.id) {
                    return $q.when(this.user);
                }
                var parent = this;
                return $http({
                    method: "GET",
                    url   : "/api/user"
                })
                .then(function (response) {
                    if (!response.data.success) {
                        $localStorage.auth.id = 0;
                        return $q.reject(response.data.reason);
                    }
                    parent.setUser(response.data.user);
                    return parent.user;
                })
                .catch(function (err) {
                    $localStorage.auth.id = 0;
                    return $q.reject(err);
                });
            },
            forgot            : function (email) {
                return $http({
                    method: "POST",
                    url   : "/api/forgot",
                    body  : {
                        email: email
                    }
                })
            },
            signOut           : function () {
                this.token            = "";
                $localStorage.token   = "";
                $localStorage.auth.id = 0;
                this.setUser(basicUser);
            },
            logout            : function () {
                var parent = this;
                return $http({
                    method: "POST",
                    url   : "/api/logout",
                    data  : {
                        token: this.token
                    }
                })
                .then(function () {
                    return true;
                })
                .catch(function () {
                    return false;
                })
                .then(function (result) {
                    parent.signOut();
                    return result;
                });
            },
            $waitForSignIn    : function () {
                if (this.user.id) {
                    return $q.when(this.user);
                }
                if ($localStorage.auth.id) {
                    return this.getUser();
                }
                return $q.when(this.user);
            },
            $requireSignIn    : function () {
                if (!$localStorage.auth.id) {
                    return $q.reject("AUTH_REQUIRED");
                }
                var parent = this;
                if (!this.user.id) {
                    return this.getUser()
                    .then(function () {
                        if (!parent.check()) {
                            return $q.reject("AUTH_REQUIRED");
                        }
                        return $q.when(parent.user);
                    }).catch(function () {
                        return $q.reject("AUTH_REQUIRED");
                    });
                }
                return $q.when(this.user);
            },
            $requireAdmin     : function () {
                if (!$localStorage.auth.id) {
                    return $q.reject("AUTH_REQUIRED");
                }
                var parent = this;
                if (!this.user.id) {
                    return this.getUser()
                    .then(function () {
                        if (!parent.check()) {
                            return $q.reject("AUTH_REQUIRED");
                        }
                        if (!parent.isAdmin()) {
                            return $q.reject("UNAUTHORIZED");
                        }
                        return $q.when(parent.user);
                    }).catch(function () {
                        return $q.reject("AUTH_REQUIRED");
                    });
                }
                if (!this.isAdmin()) {
                    return $q.reject("UNAUTHORIZED");
                }
                return $q.when(this.user);
            }
        };
    }

    /**
     * These variables and functions are used to provide a basic paging and sorting system.
     */
    function Paging() {
        /**
         * @param {Object} controls - The object containing the pagination controls
         * @param {Array} collection - The array of objects to paginate
         */
        return function (collection, controls) {
            this.loading       = false;
            this.fn            = null;
            this.controls      = {
                relations   : (controls && "relations" in controls ? controls.relations : ""),
                quantity    : (controls && "quantity" in controls ? controls.quantity : 50),
                propertyName: (controls && "propertyName" in controls ? controls.propertyName : "id"),
                reverse     : (controls && "reverse" in controls ? controls.reverse : false),
                currentPage : (controls && "currentPage" in controls ? controls.currentPage : 0),
                search      : (controls && "search" in controls ? controls.search : {})
            };
            this.defaults      = JSON.stringify(this.controls);
            this.collection    = collection;
            this.filtered      = [];
            this.totalCount    = false;
            this.filteredCount = 0;

            this.getTotal         = function () {
                return this.totalCount === false ? this.collection.length : this.totalCount;
            };
            this.getFiltered      = function () {
                return this.totalCount === false ? this.filtered.length : this.filteredCount;
            };
            this.getTotalFiltered = function () {
                return this.totalCount === false ? this.collection.length - this.filtered.length : this.totalCount - this.filteredCount;
            };

            this.setControl      = function (control, value) {
                this.controls[control] = value;
            };
            this.resetControls   = function () {
                this.controls = JSON.parse(this.defaults);
            };
            this.clearSearch     = function () {
                this.controls.search = JSON.parse(this.defaults).search;
            };
            this.clearCollection = function () {
                this.collection = [];
                this.setPage(0);
            };

            this.setCollection     = function (collection, total, filtered) {
                this.collection    = collection;
                this.totalCount    = total !== undefined ? total : false;
                this.filteredCount = total !== undefined ? filtered : 0;
                return this;
            };
            this.setFakeCollection = function (num) {
                if (!num) {
                    return;
                }
                this.collection = new Array(num);
            };

            this.setFiltered = function (items) {
                this.filtered = items;
            };

            this.setPageChangeFn = function (fn) {
                this.fn = fn;
            };

            this.remove  = function (item, property) {
                if (!property || !item || !(property in item))
                    return;
                var index = this.collection.map(function (e) {
                    return e[property];
                }).indexOf(item[property]);

                if (index >= 0)
                    this.collection.splice(index, 1);
            };
            this.replace = function (item, property, newItem) {
                if (!property || !item || !(property in item))
                    return -1;
                var index = this.collection.map(function (e) {
                    return e[property];
                }).indexOf(item[property]);

                if (index >= 0) {
                    this.collection[index] = newItem;
                    return 1;
                }
                return 0;
            };
            this.getText = function (lang) {
                var text = "";
                if (!lang || lang === "el") {
                    text = 'Αποτελέσματα <b>' + (this.getFiltered() > 0 ? this.controls.currentPage * this.controls.quantity + 1 : 0) + '</b>';
                    text += ' εώς <b>' + (this.getFiltered() > (this.controls.currentPage + 1) * this.controls.quantity ? (this.controls.currentPage + 1) * this.controls.quantity : this.getFiltered()) + '</b>';
                    text += ' από <b>' + (this.getFiltered()) + '</b> σύνολο (<b>' + (this.getTotalFiltered()) + '</b> filtered)';
                } else {
                    text = 'Results <b>' + (this.getFiltered() > 0 ? this.controls.currentPage * this.controls.quantity + 1 : 0) + '</b>';
                    text += ' to <b>' + (this.getFiltered() > (this.controls.currentPage + 1) * this.controls.quantity ? (this.controls.currentPage + 1) * this.controls.quantity : this.getFiltered()) + '</b>';
                    text += ' from <b>' + (this.getFiltered()) + '</b> total (<b>' + (this.getTotalFiltered()) + '</b> filtered)';
                }
                return text;
            };

            this.sortBy = function (propertyName) {
                this.controls.reverse      = (this.controls.propertyName === propertyName) ? !this.controls.reverse : false;
                this.controls.propertyName = propertyName;
                this.setPage(this.controls.currentPage, true);
            };

            this.range = function () {
                var rsiz      = Math.ceil(this.filtered.length / this.controls.quantity);
                var rangeSize = (rsiz > 5) ? 5 : rsiz;
                var ret       = [];
                var start;

                start = this.controls.currentPage;
                if (start > this.pageCount() - rangeSize - 1) {
                    start = this.pageCount() - rangeSize;
                }

                for (var i = start; i < start + rangeSize; i++) {
                    ret.push(i);
                }
                return ret;
            };

            this.pageCount = function () {
                var totalItems = this.getFiltered();
                var quantity   = this.controls.quantity || 1;
                return Math.ceil(totalItems / quantity);
            };

            this.prevPage = function () {
                if (this.controls.currentPage > 0) {
                    this.setPage(this.controls.currentPage - 1, true);
                }
            };

            this.nextPage = function () {
                if (this.controls.currentPage < this.pageCount() - 1) {
                    this.setPage(this.controls.currentPage + 1, true);
                }
            };

            this.prevPageDisabled = function () {
                return this.controls.currentPage === 0 ? "disabled" : "";
            };

            this.nextPageDisabled = function () {
                return this.controls.currentPage === this.pageCount() - 1 ? "disabled" : "";
            };

            this.setPage = function (page, trigger) {
                if (!page) {
                    page = 0;
                }
                this.controls.currentPage = page;
                trigger && this.refresh();
            };

            this.refresh = function () {
                if (this.fn !== null && typeof this.fn === "function") {
                    this.fn(this.controls);
                }
            };

        }
    }

    function Modal() {
        return function (defaultModel) {
            var defaultCopy = angular.copy(defaultModel || {});
            return {
                title    : "",
                model    : {},
                element  : null,
                init     : function (elem) {
                    this.element = $(elem);
                    this.element.on("hide.bs.modal", this.clear.bind(this));
                },
                clear    : function () {
                    this.model = angular.copy(defaultCopy);
                    this.onClear();
                },
                open     : function (model) {
                    if (!this.element) {
                        return;
                    }
                    this.model = model;
                    this.element.modal("show");
                },
                set      : function (key, value) {
                    if (!this.model) {
                        return false;
                    }
                    this.model[key] = value;
                    return true;
                },
                close    : function () {
                    this.element.modal("hide");
                },
                save     : angular.noop,
                onClear  : angular.noop,
                calendars: {}
            };
        }
    }

    function Toast() {
        return function (element, toastOptions) {
            var toast = {
                title  : "",
                date   : moment(),
                element: null,
                init   : function (elem, options) {
                    options = options || {
                        autohide: false
                    };

                    this.element = $("#" + elem).toast(options);
                    this.element.on("hide.bs.toast", this.clear.bind(this));
                    return this;
                },
                clear  : function () {
                    this.title = "";
                    this.body  = "";
                },
                open   : function (title, date) {
                    if (!this.element) {
                        return;
                    }
                    this.title = title;
                    this.date  = date || moment();
                    this.element.toast("show");
                },
                close  : function () {
                    this.element.toast("hide");
                    this.onDismiss();
                },
                onDismiss : angular.noop
            };
            return toast.init(element, toastOptions);
        }
    }

    ModelController.$inject = ["Paging", "$q", "Modal", "s2ab"];

    function ModelController(Paging, $q, Modal, s2ab) {
        return function (Model, options, settings) {
            settings        = settings || {};
            var vm          = this;
            vm.searchProperty = settings.searchProperty || "name";
            vm.defaultModel = settings.defaultModel || {};
            vm.dates        = settings.dates || [];
            vm.options      = {
                debounce: 500
            };
            vm.loading      = false;
            vm.form         = null;
            vm.modal        = null;
            vm.results      = [];
            vm.onSelect     = angular.noop;
            vm.onReset      = angular.noop;
            vm.excelLoading = false;
            vm.resource     = Model;

            vm.toggleSearchProperty = function (a, b) {
                if (vm.searchProperty === a) {
                    vm.searchProperty = b;
                } else {
                    vm.searchProperty = a;
                }
            };
            vm.setModal = function (modal) {
                vm.modal      = modal;
                vm.modal.save = vm.updateOrCreate;
            };

            vm.list  = new Paging([], angular.copy(options));
            vm.query = function () {
                vm.list.loading = true;
                return Model.query(vm.list.controls).$promise.then(function (data) {
                    var key = data.key;
                    vm.list.setCollection(data[key] || [], data.total, data.filtered);
                    vm.list.loading = false;
                });
            };
            vm.list.setPageChangeFn(vm.query);

            vm.remove = function (customer, resolve) {
                alertify.confirm("Είστε σίγουρος/η?", function () {
                    Model.remove({id: customer.id}).$promise
                    .then(function (result) {
                        if (result.success) {
                            vm.list.remove(customer, "id");
                            if (typeof resolve === "function") {
                                resolve(true);
                            }
                        } else {
                            if (typeof resolve === "function") {
                                resolve(false);
                            }
                        }
                    }).catch(function () {
                        if (typeof resolve === "function") {
                            resolve(false);
                        }
                    })
                }, function () {
                    if (typeof resolve === "function") {
                        resolve(false);
                    }
                });
            };

            vm.create = function (c) {
                return Model.save(c).$promise.then(function (response) {
                    if (!response.success) {
                        alertify.error(response.reason || "Σφάλμα");
                        return null;
                    }
                    alertify.success("Επιτυχία");
                    if (response[response.key] && response[response.key].id) {
                        return Model.get({id: response[response.key].id, relations: vm.list.controls.relations}).$promise;
                    }
                    return response;
                }).then(function (response) {
                    var key = response.key;
                    if (response[key]) {
                        vm.list.collection.unshift(response[key]);
                    }
                    return response;
                })
            };

            vm.findOne = function (query) {
                if (!query.relations) {
                    query.relations = vm.list.controls.relations;
                }
                return Model.get(query).$promise;
            }

            vm.update = function (c) {
                return Model.update(c).$promise.then(function (response) {
                    if (!response.success) {
                        alertify.error(response.reason || "Σφάλμα");
                        return false;
                    }
                    var key = response.key;
                    if (response[key]) {
                        vm.list.replace(c, "id", c);
                    }
                    alertify.success("Επιτυχία");
                    return true;
                });
            };

            vm.updateOrCreate = function () {
                if (!vm.form) {
                    return $q.when(false);
                }
                return (vm.form.id ? vm.update(vm.form) : vm.create(vm.form))
                .then(function (result) {
                    if (result) {
                        vm.modal.close();
                    }
                    return result;
                })
                .catch(function (err) {
                    console.error(err);
                    return false;
                });
            };

            vm.stage    = function (item) {
                item              = item || vm.defaultModel;
                var formattedItem = angular.copy(item);
                if (vm.dates && vm.dates.length) {
                    vm.dates.forEach(function (d) {
                        if (formattedItem[d]) {
                            if (formattedItem[d].length === 4 || typeof formattedItem[d] === "number") {
                                // formattedItem[d] = moment.utc(formattedItem[d]).toDate();
                            } else {
                                formattedItem[d] = moment.utc(formattedItem[d]).toDate();
                            }
                        }
                    })
                }
                vm.form = formattedItem;
                if (vm.modal) {
                    vm.modal.open(vm.form);
                }
            };

            vm.toggle = function (item) {
                item.selected = !item.selected;
                return item.selected;
            }

            vm.populate = function (values, property) {
                if (Array.isArray(values)) {
                    values = values.filter(function (val) {
                        return !!val;
                    });
                    if (!values.length) {
                        return Promise.resolve([]);
                    }
                } else if (typeof values === "string") {
                    if (!values) {
                        return Promise.resolve([]);
                    }
                } else if (typeof values === "number") {
                    if (!values) {
                        return Promise.resolve([]);
                    }
                } else {
                    return Promise.resolve([]);
                }
                return vm.search(values, property);
            }

            vm.search = function (search, property, options) {
                property = property || vm.searchProperty || "name";
                this.loading = true;
                var parent = this;
                if (!options) {
                    options = {
                        quantity: 5,
                        currentPage: 0,
                        search: {}
                    };
                }
                options.quantity = options.quantity || 5;
                options.currentPage = options.currentPage || 0;
                if (!options.search) {
                    options.search = {};
                }
                options.search[property] = search;
                return Model.query(options).$promise
                .then(function (data) {
                    var key = data.key;
                    parent.results = data[key] || [];
                    return parent.results;
                }).catch(function (err) {
                    console.log(err);
                    return [];
                }).then(function (results){
                    parent.loading = false;
                    return results;
                });
            };

            vm.createAndSet = function (c) {
                vm.create(c).then(function (res) {
                    if (res) {
                        vm.results = [res];
                    }
                });
            }

            vm.excel = function () {
                vm.excelLoading = true;
                var query = angular.copy(vm.list.controls);
                query.excel = 1;
                return Model.query(query).$promise.then(function (data) {
                    vm.excelLoading = false;
                    if (data.success) {
                        var bin = atob(data.base64);
                        var ab = s2ab(bin);
                        var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
                        saveAs(blob, data.filename);
                    }
                });
            }

            vm.setModal(new Modal(vm.defaultModel));
        }
    }

    function resourceFul(custom) {
        var resource = {
            query : {
                method : 'GET',
                isArray: false
            },
            get   : {
                method: 'GET'
            },
            save  : {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            remove: {
                method: 'DELETE'
            }
        };
        if (custom && custom.action) {
            resource[custom.action] = custom;
        }
        return resource;
    }

    TypeAhead.$inject = ["$q"];

    function TypeAhead($q) {
        return function (ctrl) {
            var service = {
                resources       : {},
                loaders         : {},
                models          : {},
                search          : {},
                query           : angular.noop,
                ModelCtrl       : null,
                options         : {
                    debounce: 250
                },
                registerResource: function (itemProp, Resource) {
                    this.resources[itemProp] = Resource;
                    return this;
                },
                init            : function (ModelCtrl) {
                    if (!ModelCtrl) {
                        return this;
                    }
                    this.ModelCtrl = ModelCtrl;
                    this.search    = ModelCtrl.list.controls.search;
                    this.query     = ModelCtrl.query;
                    this.options   = ModelCtrl.options;
                    return this;
                },
                picked          : function ($item, itemProp, searchProp) {
                    if (!this.ModelCtrl) {
                        return this.query($item);
                    }
                    this.search[itemProp] = $item[searchProp];
                    return this.query();
                },
                reset           : function (itemProp) {
                    if (!itemProp) {
                        this.models = {};
                        this.search = {};
                        return this.query();
                    }
                    this.models[itemProp] = "";
                    this.search[itemProp] = '';
                    return this.query();
                },
                unique          : function (arr, prop) {
                    var a = [];
                    for (var i = 0, l = arr.length; i < l; ++i) {
                        var property = arr[i][prop];
                        if (!property) continue;
                        var res = true;
                        for (var j = 0; j < a.length; ++j) {
                            if (a[j][prop] === property) {
                                res = false;
                                break;
                            }
                        }
                        if (res) {
                            a.push(arr[i]);
                        }
                    }
                    return a;
                },
                getResource     : function (search, itemProp, searchProp, notUnique) {
                    this.loaders[itemProp] = true;
                    if (!this.resources[itemProp]) {
                        return $q.when([]);
                    }
                    var parent = this;
                    return this.resources[itemProp].query({
                        quantity   : 5,
                        currentPage: 0,
                        search     : {[searchProp]: search}
                    }).$promise
                    .then(function (data) {
                        parent.loaders[itemProp] = false;
                        var key                  = data.key;
                        return data[key] || [];
                    }).catch(function (err) {
                        console.log(err);
                        parent.loaders[itemProp] = false;
                        return [];
                    })
                    .then(function (results) {
                        return notUnique ? results : parent.unique(results, searchProp);
                    });
                }
            };
            return service.init(ctrl);
        }
    }

    Customer.$inject = ["$resource"];

    function Customer($resource) {
        return $resource("/api/customers/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    CustomerChris.$inject = ["$resource"];

    function CustomerChris($resource) {
        return $resource("/api/customers-chris/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    Protocol.$inject = ["$resource"];

    function Protocol($resource) {
        const expiring = {
            action: "expiring",
            method: "POST",
            url: "/api/protocols/expiring"
        }
        return $resource("/api/protocols/:id", {id: '@id'}, resourceFul(expiring), {
            stripTrailingSlashes: true
        })
    }

    Doy.$inject = ["$resource"];

    function Doy($resource) {
        return $resource("/api/doys/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    Activity.$inject = ["$resource"];

    function Activity($resource) {
        return $resource("/api/activities/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    Contact.$inject = ["$resource"];

    function Contact($resource) {
        return $resource("/api/contacts/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    Study.$inject = ["$resource"];

    function Study($resource) {
        return $resource("/api/studies/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    Service.$inject = ["$resource"];

    function Service($resource) {
        return $resource("/api/services/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    Municipality.$inject = ["$resource"];

    function Municipality($resource) {
        return $resource("/api/municipalities/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    User.$inject = ["$resource"];

    function User($resource) {
        return $resource("/api/users/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    Role.$inject = ["$resource"];

    function Role($resource) {
        return $resource("/api/roles/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    Task.$inject = ["$resource"];

    function Task($resource) {
        return $resource("/api/tasks/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

    TaskStudy.$inject = ["$resource"];

    function TaskStudy($resource) {
        return $resource("/api/task-studies/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }


    Fem.$inject = ["$resource"];

    function Fem($resource) {
        return $resource("/api/fems/:id", {id: '@id'}, resourceFul(), {
            stripTrailingSlashes: true
        })
    }

})();
