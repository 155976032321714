(function () {
    'use strict';

    angular
    .module("app")
    .directive('errSrc', errSrc)
    .directive('toast', toast)
    .directive('disallow', disallow)
    .directive('price', price)
    .directive('ckEditor', ckEditor)
    .directive("pagination", pagination)
    .directive("resourceTypeAhead", resourceTypeAhead)
    .directive("customerCard", customerCard)
    .directive("contactCard", contactCard)
    .directive("femCard", femCard)
    .directive("protocolTable", protocolTable)
    .directive("sortMe", sortMe)
    .directive("file", fileDirective);

    function fileDirective() {
        return {
            scope: {
                file: "="
            },
            link: function (scope, el, attrs) {
                el.bind("change", function (event) {
                    var file = event.target.files[0];
                    scope.file = file ? file : undefined;
                    scope.$apply();
                });
            }
        };
    }

    function price() {
        return {
            restrict: "A",
            require : 'ngModel',
            link    : function (scope, element, attrs, ngModel) {
                attrs.$set('ngTrim', "false");
                var thousands    = !!attrs.price;
                var formatter    = function (str, isNum) {
                    str    = String(Number(str || 0) / (isNum ? 1 : 100));
                    str    = (str === '0' ? '0.0' : str).split('.');
                    str[1] = str[1] || '0';
                    return (thousands ? str[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : str[0]) + '.' + (str[1].length === 1 ? str[1] + '0' : str[1]);
                };
                var updateView   = function (val) {
                    scope.$applyAsync(function () {
                        ngModel.$setViewValue(val || '');
                        ngModel.$render();
                    });
                };
                var parseNumber  = function (val) {
                    var newVal;
                    var modelString = formatter(ngModel.$modelValue, true);
                    var sign        = {
                        pos: /[+]/.test(val),
                        neg: /[-]/.test(val)
                    };
                    sign.has        = sign.pos || sign.neg;
                    sign.both       = sign.pos && sign.neg;

                    if (!val || sign.has && val.length === 1 || ngModel.$modelValue && Number(val) === 0) {
                        newVal = (!val || ngModel.$modelValue && Number() === 0 ? '' : val);
                        if (ngModel.$modelValue !== newVal)
                            updateView(newVal);

                        return '';
                    } else {
                        var valString = String(val || '');
                        var newSign   = (sign.both && ngModel.$modelValue >= 0 || !sign.both && sign.neg ? '-' : '');
                        newVal        = valString.replace(/[^0-9]/g, '');
                        var viewVal   = newSign + formatter(angular.copy(newVal));

                        if (modelString !== valString)
                            updateView(viewVal);

                        return (Number(newSign + newVal) / 100) || 0;
                    }
                };
                var formatNumber = function (val) {
                    if (val) {
                        var str = String(val).split('.');
                        str[1]  = str[1] || '0';
                        val     = str[0] + '.' + (str[1].length === 1 ? str[1] + '0' : str[1]);
                    }
                    return parseNumber(val);
                };

                ngModel.$parsers.push(parseNumber);
                ngModel.$formatters.push(formatNumber);
            }
        };
    }

    function disallow() {
        return {
            restrict: "A",
            require : "ngModel",
            scope   : {
                disallow: "@"
            },
            link    : function (scope, element, attrs, ngModel) {
                var regex = new RegExp(attrs.disallow, "g");

                var formatViewValue = function (value) {
                    var viewValue      = (value || "").toString().replace(regex, "");
                    ngModel.$viewValue = viewValue;
                    ngModel.$render();
                    // Return what we want the model value to be
                    return viewValue;
                };

                ngModel.$parsers.push(formatViewValue);
                ngModel.$formatters.push(formatViewValue);
            }
        }
    }

    function toast() {
        return {
            restrict  : "E",
            transclude: true,
            replace   : true,
            template  : toastTemplate,
            scope     : {
                id     : "@id",
                title  : "@?title",
                message  : "=?message",
                options: "=?options",
                toast  : "=?toast",
                date   : "=?date",
                dismiss : "&?dismiss"
            },
            controller: ["$scope", "Toast", function ($scope, Toast) {
                $scope.toast = new Toast($scope.id, $scope.options);
                if ($scope.title) {
                    $scope.toast.open($scope.title, $scope.date);
                }
                if ($scope.dismiss) {
                    $scope.toast.onDismiss = $scope.dismiss;
                }
            }]
        };
    }

    const toastTemplate = `<div class="toast toast-dark fade show" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
        <strong class="mr-auto">{{toast.title}}</strong>
        <small class="text-light">{{ toast.date | fromNow }}</small>
        <button type="button" class="ml-2 mb-1 close text-light" ng-click="toast.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="toast-body">
        <div>{{message}}</div>
        <div ng-transclude></div>
    </div>
</div>`;

    function errSrc() {
        return {
            link: function (scope, element, attrs) {
                element.bind('error', function () {
                    if (attrs.src !== attrs.errSrc) {
                        attrs.$set('src', attrs.errSrc);
                    }
                });

                attrs.$observe('ngSrc', function (value) {
                    if (!value && attrs.errSrc) {
                        attrs.$set('src', attrs.errSrc);
                    }
                });
            }
        }
    }

    function ckEditor() {
        return {
            require: '?ngModel',
            link   : function (scope, elm, attr, ngModel) {
                var editorOptions;
                if (attr.ckEditor === 'minimal') {
                    // minimal editor
                    editorOptions = {
                        toolbar        : [
                            {name: 'document', items: ['Source', 'Save', 'EditMode']},
                            {name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteFromWord', '-', 'Undo', 'Redo']},
                            {name: 'basic', items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat']},
                            {name: 'links', items: ['Link', 'Unlink']},
                            {name: 'style', items: ['Font']},
                            {name: 'tools', items: []},
                            {name: 'insert', items: ['Image', 'SpecialChar']}
                        ],
                        basicEntities  : true,
                        allowedContent : true,
                        resize_enabled : false,
                        defaultLanguage: 'el',
                        language       : 'el',
                        enterMode      : CKEDITOR.ENTER_BR
                    };
                } else if (attr.ckEditor === 'full') {
                    editorOptions = {
                        basicEntities  : true,
                        allowedContent : true,
                        resize_enabled : true,
                        defaultLanguage: 'el',
                        language       : 'el',
                        enterMode      : CKEDITOR.ENTER_BR
                    };

                } else {
                    editorOptions = {
                        toolbar        : [
                            {name: 'document', items: ['Source']},
                            {name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteFromWord', '-', 'Undo', 'Redo']},
                            {name: 'basic', items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat']},
                            {name: 'links', items: ['Link', 'Unlink']},
                            {name: 'style', items: ['Font']},
                            {name: 'tools', items: []},
                            {name: 'insert', items: ['Image', 'SpecialChar']}
                        ],
                        basicEntities  : true,
                        allowedContent : true,
                        resize_enabled : false,
                        defaultLanguage: 'el',
                        language       : 'el',
                        enterMode      : CKEDITOR.ENTER_BR
                    };
                }
                var ck = CKEDITOR.replace(elm[0], editorOptions);
                if (attr.ckEditor === '') {
                    setupExtraEvents();
                }
                ck.addCommand("EnableEditModeCommand", { // create named command
                    exec    : function (edt) {
                        edt.setReadOnly(false);
                        ck.commands.EnableEditModeCommand.setState(CKEDITOR.TRISTATE_DISABLED);
                    },
                    readOnly: true
                });

                ck.ui.addButton('EditMode', { // add new button and bind our command
                    label  : "Επεξεργασία",
                    command: 'EnableEditModeCommand',
                    toolbar: 'document',
                    icon   : ''
                });
                if (!ngModel) return;
                ck.on('instanceReady', function () {
                    ck.setData(ngModel.$viewValue);
                });

                function updateModel() {
                    ngModel.$setViewValue(ck.getData());
                }

                ck.on('save', function (evt) {
                    evt.cancel();
                    updateModel();
                    ck.setReadOnly(true);
                    ck.commands.EnableEditModeCommand.setState(CKEDITOR.TRISTATE_ON);
                });

                function setupExtraEvents() {
                    ck.on('change', updateModel);
                    ck.on('key', updateModel);
                    ck.on('dataReady', updateModel);
                    ck.on('afterPaste', updateModel);
                    ck.on('selectionChange', updateModel);
                }

                ngModel.$render = function (value) {
                    ck.setData(ngModel.$viewValue);
                };
            }
        };
    }

    const pagingTemplate =
              `<nav class="d-flex">
		<div class="col-md-6 d-none d-md-flex p-2">
			<span ng-bind-html="items.getText()"></span>
		</div>
		<div class="col px-2">
            <ul class="pagination m-0 justify-content-center justify-content-md-end">
                <li class="page-item" ng-class="items.prevPageDisabled()">
                    <a class="page-link" href="" ng-click="items.prevPage()" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                    </a>
                </li>
                <li class="page-item" ng-if="items.pageCount() > 1 && items.controls.currentPage > 0">
                    <a class="page-link" href="" ng-click="items.setPage(0, true)">1</a>
                </li>

                <li class="page-item disabled" ng-show="items.pageCount() >= 4 && items.controls.currentPage >= 3">
                    <a class="page-link" href="">...</a>
                </li>
                <li class="page-item" ng-if="items.pageCount() >= 3 && items.controls.currentPage >= 2">
                    <a class="page-link" href="" ng-click="items.setPage(items.controls.currentPage-1, true)">{{items.controls.currentPage}}</a>
                </li>

                <li class="page-item active">
                    <a class="page-link" href="" ng-click="items.setPage(items.controls.currentPage, true)">{{items.controls.currentPage + 1}}</a>
                </li>

                <li class="page-item" ng-if="items.pageCount() >= 3 && items.controls.currentPage < items.pageCount() - 2">
                    <a class="page-link" href="" ng-click="items.setPage(items.controls.currentPage+1, true)">{{items.controls.currentPage + 2}}</a>
                </li>
                <li class="page-item disabled" ng-show="items.pageCount() >= 4 && items.controls.currentPage < items.pageCount() - 3">
                    <a class="page-link" href="">...</a>
                </li>

                <li class="page-item" ng-if="items.pageCount() > 1 && items.controls.currentPage < items.pageCount() - 1">
                    <a class="page-link" href="" ng-click="items.setPage(items.pageCount() - 1, true)">{{items.pageCount()}}</a>
                </li>
                <li class="page-item" ng-class="items.nextPageDisabled()">
                    <a class="page-link" href="" aria-label="Next" ng-click="items.nextPage()">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </div>
	</nav>
`;

    function pagination() {
        return {
            restrict: 'E',
            require : 'ngModel',
            scope   : {
                items: '=ngModel'
            },
            replace : true,
            template: pagingTemplate
        }
    }

    function sortMe() {
        return {
            transclude: true,
            restrict  : 'E',
            require   : 'ngModel',
            scope     : {
                items: '=ngModel',
                id   : '@propertyId',
                name : '@propertyName'
            },
            replace   : true,
            template  : sortMeTemplate
        }
    }

    const sortMeTemplate = `
    <div ng-click="items.sortBy(id)" class="sort-me">
        <span class="text-nowrap mr-1" ng-bind-html="name | unsafe" ng-if="name"></span>
        <span class="text-nowrap mr-1" ng-transclude></span>
        <span class="fa" ng-show="items.controls.propertyName === id" ng-class="{'fa-sort-asc': !items.controls.reverse, 'fa-sort-desc': items.controls.reverse}"></span>
    </div>
`;

    function contactCard() {
        return {
            transclude: true,
            restrict  : 'E',
            scope     : {
                contact: '=contact',
                cardClass: '@cardClass',
                headerClass: '@headerClass',
            },
            template  : contactTemplate
        }
    }
    const contactTemplate = `
<div class="card d-inline-block" ng-if="contact" ng-class="cardClass">
    <div class="card-header" ng-class="headerClass || 'p-3'" ng-transclude></div>
    <div class="card-body p-3">
        <table>
            <tr>
                <th class="text-right pr-2">Όνομα</th>
                <td>{{ contact.name || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Υπηρεσία</th>
                <td>{{ contact.service.name || contact.service_name || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Όνομα Πατρός</th>
                <td>{{ contact.fathers_name || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Όνομα Μητρός</th>
                <td>{{ contact.mothers_name || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Διεύθυνση</th>
                <td>{{ contact.address || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Email</th>
                <td ng-bind-html="contact.email | emailLink"></td>
            </tr>
            <tr>
                <th class="text-right pr-2">Τηλέφωνα</th>
                <td>
                    <div ng-show="contact.phone"><span class="ajs-no-selection">Τηλέφωνο:&nbsp;</span>{{ contact.phone}}</div>
                    <div ng-show="contact.phone2"><span class="ajs-no-selection">Τηλέφωνο2:&nbsp;</span>{{ contact.phone2}}</div>
                    <div ng-show="contact.mobile"><span class="ajs-no-selection">Κινητό:&nbsp;</span>{{ contact.mobile}}</div>
                    <div ng-show="contact.fax"><span class="ajs-no-selection">Fax:&nbsp;</span>{{ contact.fax}}</div>
                    <div ng-show="contact.office_number"><span class="ajs-no-selection">Γραφείο:&nbsp;</span>{{ contact.office_number }}</div>
                </td>
            </tr>
            <tr>
                <th class="text-right pr-2">ΑΦΜ</th>
                <td>{{ contact.afm || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Δ.Ο.Υ</th>
                <td>{{ contact.doy || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Ταυτότητα</th>
                <td>{{ contact.identity || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Εκδότης</th>
                <td>{{ contact.identity_issuer || "-" }}</td>
            </tr>
            <tr ng-show="contact.notes">
                <th class="text-right pr-2">Σημειώσεις</th>
                <td><div style="max-height: 100px; overflow-x: auto; white-space: pre-wrap">{{ contact.notes }}</div></td>
            </tr>
        </table>
    </div>
</div>`;

    function customerCard() {
        return {
            transclude: true,
            restrict  : 'E',
            scope     : {
                customer: '=customer',
                modal: '=modal',
                showName: '@showName',
                cardClass: '@cardClass',
                headerClass: '@headerClass',
            },
            template  : customerTemplate
        }
    }

    const customerTemplate = `
<div class="card d-inline-block" ng-if="customer" ng-class="cardClass">
    <div class="card-header" ng-class="headerClass || 'p-3'" ng-transclude></div>
    <div class="card-body p-3">
        <table>
            <tr ng-show="showName">
                <th class="text-right pr-2">Ονομασία</th>
                <td>{{ customer.name }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Δραστηριότητα</th>
                <td>{{ customer.activity || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Νόμιμος εκπρόσωπος</th>
                <td>
                    <span ng-show="!customer.representative.name">{{ customer.representative_name || "-" }}</span>
                    <a href="" ng-click="modal.open(customer.representative)" ng-show="customer.representative.name">{{customer.representative.name}}</a>
                </td>
            </tr>
            <tr>
                <th class="text-right pr-2">Συνεργάτης Μηχανικός</th>
                <td>
                    <span ng-show="!customer.partner.name">{{ customer.partner_name || "-" }}</span>
                    <a href="" ng-click="modal.open(customer.partner)" ng-show="customer.partner.name">{{customer.partner.name}}</a>
                </td>
            </tr>
            <tr>
                <th class="text-right pr-2">Διαχειριστής υπόθεσης</th>
                <td>{{ customer.admin.name || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Διεύθυνση</th>
                <td>{{ customer.address || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Email</th>
                <td ng-bind-html="customer.email | emailLink"></td>
            </tr>
            <tr>
                <th class="text-right pr-2">Τηλέφωνο</th>
                <td>{{ customer.phone || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Κινητό</th>
                <td>{{ customer.mobile || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Fax</th>
                <td>{{ customer.fax || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">ΑΦΜ</th>
                <td>{{ customer.afm || "-" }}</td>
            </tr>
            <tr>
                <th class="text-right pr-2">Δ.Ο.Υ</th>
                <td>{{ customer.doy || "-" }}</td>
            </tr>
            <tr ng-show="customer.comment">
                <th class="text-right pr-2">Σχόλιο</th>
                <td><div style="max-height: 100px; overflow-x: auto; white-space: pre-wrap">{{ customer.comment }}</div></td>
            </tr>
            <tr ng-if="customer.contacts.length">
                <th class="text-right pr-2">Σχετιζόμενοι</th>
                <td>
                    <ul class="list-group d-inline-block">
                        <li class="list-group-item py-1 px-2" ng-repeat="contact in customer.contacts">
                            <a href="" ng-click="modal.open(contact)">{{contact.name}}</a> <span ng-if="contact.pivot.reason">({{contact.pivot.reason}})</span>
                        </li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</div>`;

    function femCard() {
        return {
            transclude: true,
            restrict  : 'E',
            scope     : {
                item: '=fem',
                modal: '=modal',
                cardClass: '@cardClass',
                headerClass: '@headerClass',
            },
            template  : femTemplate
        }
    }

    const femTemplate = `
<div class="card" ng-if="item" ng-class="cardClass || 'd-inline-block'">
    <div class="card-header" ng-class="headerClass || 'p-3'" ng-transclude></div>
    <div class="card-body p-3">
        <table>
            <tr>
                <th>Α/Α Παραστατικού</th>
                <td colspan="4">{{item.apy}}</td>
            </tr>
            <tr>
                <th>Ημ/νια ΑΠΥ</th>
                <td colspan="4">{{item.apy_date | moment:'DD/MM/YYYY'}}</td>
            </tr>
            <tr>
                <th>Είδος Παραστατικού</th>
                <td colspan="4">{{item.invoice_type}}</td>
            </tr>
            <tr>
                <th>Σειρά Παραστατικού</th>
                <td colspan="4">{{item.invoice_number}}</td>
            </tr>
            <tr>
                <th>Παρακράτηση (%)</th>
                <td colspan="4">{{item.engineer_percent}}</td>
            </tr>
            <tr>
                <th>Εταιρεία Τιμολόγησης</th>
                <td colspan="4">
                    <span ng-show="!item.engineer">{{item.engineer_name}}</span>
                    <a href="" ng-click="modal.open(item.engineer)" ng-show="item.engineer">{{item.engineer_name}}</a>
                </td>
            </tr>
            <tr>
                <th>Συνεργάτης Μηχανικός</th>
                <td colspan="4">
                    <span ng-show="!item.partner">-</span>
                    <a href="" ng-click="modal.open(item.partner)" ng-show="item.partner">{{item.partner.name}}</a>
                </td>
            </tr>
            <tr>
                <th>Εργοδότης</th>
                <td colspan="4">
                    <span ng-show="!item.employer">{{item.employer_name}}</span>
                    <a href="" ng-click="modal.open(item.employer)" ng-show="item.employer">{{item.employer_name}}</a>
                </td>
            </tr>
            <tr>
                <th>Δ/ΣΗ Εργοδότη</th>
                <td colspan="4">{{item.employer_address}}</td>
            </tr>
            <tr>
                <th>ΑΦΜ Εργοδότη</th>
                <td colspan="4">{{item.employer_afm}}</td>
            </tr>
            <tr>
                <th>ΔΟΥ Εργοδότη</th>
                <td colspan="4">{{item.employer_doy}}</td>
            </tr>
            <tr>
                <th>Ιδιώτης-Εργολάβος</th>
                <td colspan="4">
                    <span ng-show="!item.contractor">{{item.contractor_name}}</span>
                    <a href="" ng-click="modal.open(item.contractor)" ng-show="item.contractor">{{item.contractor_name}}</a>
                </td>
            </tr>
            <tr>
                <th>Αξία Έργου</th>
                <td colspan="4">{{item.task_value}}</td>
            </tr>
            <tr>
                <th>Αμοιβή Μελέτης</th>
                <td colspan="4">{{item.task_reward}}</td>
            </tr>
            <tr>
                <th>Αμοιβή Επίβλεψης</th>
                <td colspan="4">{{item.supervision_reward}}</td>
            </tr>
            <tr>
                <th>Φόρος Μελέτης</th>
                <td colspan="4">{{item.task_tax}}</td>
            </tr>
            <tr>
                <th>Φόρος Επίβλεψης</th>
                <td colspan="4">{{item.supervision_tax}}</td>
            </tr>
            <tr>
                <th>Σύνολο Φόρου</th>
                <td colspan="4">{{item.total_tax}}</td>
            </tr>
            <tr>
                <th>ΔΟΥ Έργου</th>
                <td colspan="4">{{item.task_doy}}</td>
            </tr>
            <tr>
                <th>Δ/ση Έργου</th>
                <td colspan="4">{{item.task_address}}</td>
            </tr>
            <tr>
                <th>Περιγραφή</th>
                <td colspan="4">{{item.description}}</td>
            </tr>
            <tr>
                <th>Διαμερισμάτα m2</th>
                <td colspan="4">{{item.apartments_m2}}</td>
            </tr>
            <tr>
                <th>Επαγγελ Χώροι m2</th>
                <td colspan="4">{{item.prof_space_m2}}</td>
            </tr>
            <tr>
                <th>Λοιποί Χώροι m2</th>
                <td colspan="4">{{item.other_m2}}</td>
            </tr>
            <tr>
                <th>Έναρξη Έτος</th>
                <td colspan="4">{{item.start_year}}</td>
            </tr>
            <tr>
                <th>Ημερομηνία έκδοσης</th>
                <td colspan="4"><span ng-if="item.now_date">{{item.now_date | moment:'DD/MM/YYYY'}}</span></td>
            </tr>
            <tr>
                <th>Παρατηρήσεις</th>
                <td colspan="4">{{item.remarks}}</td>
            </tr>
        </table>
    </div>
</div>`;


    function resourceTypeAhead() {
        return {
            restrict: "E",
            replace : true,
            template: typeAheadTemplate,
            scope   : {
                inputId    : "@?inputId",
                placeholder: "@?placeholder",
                typeAhead  : "=typeAhead",
                itemProp   : "@itemProp",
                searchProp : "@searchProp",
                useProp    : "@useProp",
                minLength  : "=minLength",
                resource   : "=?resource",
                unique     : "=?unique",
                disabled   : "=?disabled"
            },
            link    : function (scope, elm, attr, ngModel) {
                scope.pickProperty = scope.useProp || scope.searchProp;
                scope.inputId = scope.inputId || "typeAheadTemplate-" + Math.round(Math.random() * 100000);
                if (typeof scope.resource !== "undefined" && typeof scope.typeAhead !== "undefined") {
                    scope.typeAhead.registerResource(scope.itemProp, scope.resource);
                }
            }
        };
    }

    const typeAheadTemplate = `<div class="custom-append custom-append-sm position-relative">
    <input
           id="{{inputId}}"
           type="text"
           class="form-control form-control-sm"
           placeholder="{{placeholder}}"
           ng-model="typeAhead.models[itemProp]"
           ng-model-options="typeAhead.options"
           ng-disabled="disabled"
           uib-typeahead="item as item[searchProp] for item in typeAhead.getResource($viewValue, itemProp, searchProp, !unique)"
           typeahead-loading="typeAhead.loaders[itemProp]"
           typeahead-editable="false"
           typeahead-on-select="typeAhead.picked($item, itemProp, pickProperty)"
           typeahead-show-hint="true"
           typeahead-min-length="minLength"
    />
    <div class="input-group-append custom-append-addon">
        <button type="button" class="btn btn-sm btn-outline-secondary"
                ng-click="typeAhead.reset(itemProp)"
                ng-disabled="disabled"
        >
            <i class="fa fa-times"></i>
        </button>
    </div>
</div>`;

    function protocolTable() {
        return {
            restrict  : 'E',
            scope     : {
                protocol: '=protocol',
                modal: '=modal'
            },
            template  : protocolTemplate
        }
    }

    const protocolTemplate = `
<table class="table table-bordered mb-0 table-fixed">
    <tr>
        <th>Πελάτης</th>
        <td colspan="4">
            <a href="" ng-click="modal.open(protocol.customer)">{{protocol.customer.name || protocol.customer_name}}</a>
        </td>
    </tr>
    <tr>
        <th>Αριθμός Πρωτοκόλλου</th>
        <td colspan="4">{{protocol.protocol}}</td>
    </tr>
    <tr>
        <th>Υπεύθυνος υπόθεσης</th>
        <td colspan="4">{{protocol.employee_name}}</td>
    </tr>
    <tr>
        <th>Τύπος μελέτης</th>
        <td colspan="4">{{protocol.study_type}}</td>
    </tr>
    <tr>
        <th>Ημερομηνία Κατάθεσης</th>
        <td colspan="4">{{protocol.application_date | moment:'DD/MM/YYYY'}}</td>
    </tr>
    <tr>
        <th>Ολοκληρώθηκε</th>
        <td colspan="4">
            <i class="fa"
               ng-class="{'fa-square-o': !protocol.complete, 'fa-check-square text-success': protocol.complete}"></i>
            <span ng-show="protocol.complete && protocol.completed_at">{{ protocol.completed_at | moment:'DD/MM/YYYY'}}</span>
        </td>
    </tr>
    <tr>
        <th>Υπηρεσία</th>
        <td colspan="4">{{protocol.service_name}}</td>
    </tr>
    <tr>
        <th>Υπάλληλος Υπηρεσίας</th>
        <td colspan="4">{{protocol.service_employee}}</td>
    </tr>
    <tr>
        <th>Παράβολο (κόστος)</th>
        <td colspan="4">
            <span ng-show="protocol.fee">{{ protocol.fee }} &euro;</span>
            <span ng-show="!protocol.fee">-</span>
        </td>
    </tr>
    <tr>
        <th>Σχόλιο</th>
        <td colspan="4">{{protocol.comment}}</td>
    </tr>
    <tr>
        <th>Ημερομηνία αυτοψίας</th>
        <td colspan="4">{{protocol.autopsy_date | moment:'DD/MM/YYYY'}}</td>
    </tr>
    <tr>
        <th>Ημερομηνία λήξης</th>
        <td colspan="4">{{protocol.expiry_date | moment:'DD/MM/YYYY'}}</td>
    </tr>
</table>`;
})();
