(function () {
    'use strict';

    angular
    .module("app")
    .filter('orderObjectBy', orderObjectBy)
    .filter('toArray', toArray)
    .filter("unsafe", unsafe)
    .filter('offset', offset)
    .filter('moment', momentFilter)
    .filter('momentFromNow', momentFromNow)
    .filter('momentIsPast', momentIsPast)
    .filter('fromNow', fromNow)
    .filter('phoneLink', phoneLink)
    .filter('emailLink', emailLink)
    .filter('unique', unique);

    function fromNow() {
        return function(date, ago) {
            try {
                return moment(date).fromNow(ago);
            } catch (e) {
                return "";
            }
        };
    }
    function unique() {
        return function(collection, keyname) {
            var output = [],
                keys = [];

            angular.forEach(collection, function(item) {
                var key = item[keyname];
                if(keys.indexOf(key) === -1) {
                    keys.push(key);
                    output.push(item);
                }
            });

            return output;
        };
    }

    function momentFilter() {
        return function (input, format, timezone) {
            if (!input) {
                return "";
            }
            var m = moment(input);
            if (timezone) {
                m.tz(timezone);
            } else {
                m.tz(moment.tz.guess());
            }
            if (!m.isValid()) {
                return "";
            }
            return m.format(format);
        }
    }

    function momentFromNow() {
        return function (input, timezone) {
            if (!input) {
                return "";
            }
            var m = moment(input);
            if (timezone) {
                m.tz(timezone);
            } else {
                m.tz(moment.tz.guess());
            }
            if (!m.isValid()) {
                return "";
            }
            return m.fromNow();
        }
    }

    function momentIsPast() {
        return function (input, timezone) {
            if (!input) {
                return "";
            }
            var m = moment(input);
            if (timezone) {
                m.tz(timezone);
            } else {
                m.tz(moment.tz.guess());
            }
            if (!m.isValid()) {
                return "";
            }
            return m.isBefore() ? 'red' : 'green';
        }
    }
    phoneLink.$inject = ["$sce"];
    function phoneLink($sce) {
        return function (input) {
            input = (input || "").toLowerCase();
            var regex = /(?:[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*)/g;
            var result = [];
            input.replace(regex, function (phone) {
                result.push(`<a href="tel:${phone}">${phone}</a>`);
            });

            return $sce.trustAsHtml(result.join("<br/>"));
        }
    }
    emailLink.$inject = ["$sce"];
    function emailLink($sce) {
        return function (input) {
            input = (input || "").toLowerCase();
            var regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
            var result = [];
            input.replace(regex, function (email) {
                result.push(`<a href="mailto:${email}" target="_blank">${email}</a>`);
            });

            return $sce.trustAsHtml(result.join(""));
        }
    }
    unsafe.$inject = ["$sce"];
    function unsafe($sce) {
        return $sce.trustAsHtml;
    }

    function orderObjectBy(){
        return function (obj, field, reverse) {
            if (!(obj instanceof Object)) {
                return obj;
            }
            var newArray = Object.keys(obj).map(function (key) {
                return Object.defineProperty(obj[key], '$key', {__proto__: null, value: key});
            });

            newArray.sort(function (a, b) {
                return (a[field] > b[field] ? 1 : -1);
            });
            if(reverse) newArray.reverse();
            return newArray;
        }

    }

    function toArray() {
        return function (obj) {
            if (!(obj instanceof Object)) {
                return obj;
            }

            return Object.keys(obj).map(function (key) {
                return Object.defineProperty(obj[key], '$key', {__proto__: null, value: key});
            });
        }
    }

    function offset(){
        return function(input, start){
            start = parseInt(start, 10);
            return input.slice(start);
        };
    }

})();
