(function () {
    'use strict';
    angular
    .module("app")
    .controller('DoysController', DoysController);

    DoysController.$inject = ['$scope', '$rootScope', 'ModelController', 'Doy'];

    function DoysController($scope, $rootScope, ModelController, Doy) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;

        var options = {propertyName: "id", reverse: true, quantity: 50};
        var defaultDoy = {
            name: "",
            code: "",
            type: "ΔΟΥ",
            address: "",
            tk: "",
            city: "",
            county: "",
            phone: "",
            fax: ""
        };
        var settings = {
            defaultModel: defaultDoy
        };

        $scope.Doys = new ModelController(Doy, options, settings);
        $rootScope.searchBox.link($scope.Doys.list);
    }
})();
