(function () {
    'use strict';
    angular
    .module("app")
    .controller('ContactsController', ContactsController);

    ContactsController.$inject = ['$scope', '$rootScope', 'currentAuth', 'Modal', 'Contact', 'Service', 'Doy', 'ModelController', 'TypeAhead'];

    function ContactsController($scope, $rootScope, currentAuth, Modal, Contact, Service, Doy, ModelController, TypeAhead) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;
        var searchOptions = {propertyName: "id", reverse: true, quantity: 50};
        var options = {propertyName: "id", reverse: true, quantity: 50, relations: "companies service"};
        var defaultContact = {
            "name"           : "",
            "fathers_name"   : "",
            "mothers_name"   : "",
            "service_id"     : null,
            "service_name"   : "",
            "afm"            : "",
            "doy"            : "",
            "identity"       : "",
            "identity_issuer": null,
            "email"          : "",
            "phone"          : "",
            "phone2"         : "",
            "mobile"         : "",
            "fax"            : "",
            "office_number"  : "",
            "address"        : "",
            "notes"          : ""
        };
        var settings = {
            defaultModel: defaultContact
        }
        $scope.modal = new Modal();

        $scope.Contacts = new ModelController(Contact, options, settings);
        $rootScope.searchBox.link($scope.Contacts.list);

        $scope.Services = new ModelController(Service, searchOptions);
        $scope.Services.onSelect = function(item) {
            item = item || {};
            $scope.Contacts.modal.set("service_name", item.name);
        };
        $scope.Services.onReset = function() {
            $scope.Contacts.modal.model.service_id = null;
            $scope.Contacts.modal.model.service_name = ''
        };

        $scope.Doys = new ModelController(Doy, searchOptions);
        $scope.Doys.onSelect = function(item) {
            item = item || {};
            $scope.Contacts.modal.set("doy", item.name);
        };
        $scope.Doys.onReset = function() {
            $scope.Contacts.modal.model.doy = '';
        };


        $scope.typeAhead = new TypeAhead($scope.Contacts);
        $scope.typeAhead.registerResource("service_id", Service);
        $scope.typeAhead.registerResource("doy", Doy);
    }
})();
