/**
 * Created by Ignatis on 06/05/2018.
 */
(function () {
    'use strict';

    angular
    .module("app", ["ngResource", 'ui.select', "ngSanitize", 'ngMessages', 'ui.router', 'ngStorage', 'oc.lazyLoad', 'ui.bootstrap'])
    .constant('paramEncode', paramEncode)
    .constant('colorize', colorize)
    .constant('s2ab', s2ab)
    .run(run)
    .config(config);


    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    function hashCode(str) { // java String#hashCode
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    function intToRGB(i){
        var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

        return "00000".substring(0, 6 - c.length) + c;
    }

    function colorize(str) {
        return "#" + intToRGB(hashCode(str));
    }

    function paramEncode(obj) {
        var query = '', name, value, fullSubName, subName, subValue, innerObj, i;

        for (name in obj) {
            value = obj[name];

            if (value instanceof Array) {
                for (i = 0; i < value.length; ++i) {
                    subValue              = value[i];
                    fullSubName           = name + '[' + i + ']';
                    innerObj              = {};
                    innerObj[fullSubName] = subValue;
                    query += paramEncode(innerObj) + '&';
                }
            } else if (value instanceof Object) {
                for (subName in value) {
                    subValue              = value[subName];
                    fullSubName           = name + '[' + subName + ']';
                    innerObj              = {};
                    innerObj[fullSubName] = subValue;
                    query += paramEncode(innerObj) + '&';
                }
            } else if (value !== undefined && value !== null)
                query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
        }

        return query.length ? query.substr(0, query.length - 1) : query;
    }

    run.$inject = ['$rootScope', '$location', '$state', 'Auth', '$localStorage'];

    function run($rootScope, $location, $state, Auth, $localStorage) {
        moment.defineLocale('el', {
            monthsNominativeEl: 'Ιανουάριος_Φεβρουάριος_Μάρτιος_Απρίλιος_Μάιος_Ιούνιος_Ιούλιος_Αύγουστος_Σεπτέμβριος_Οκτώβριος_Νοέμβριος_Δεκέμβριος'.split(
                '_'
            ),
            monthsGenitiveEl: 'Ιανουαρίου_Φεβρουαρίου_Μαρτίου_Απριλίου_Μαΐου_Ιουνίου_Ιουλίου_Αυγούστου_Σεπτεμβρίου_Οκτωβρίου_Νοεμβρίου_Δεκεμβρίου'.split(
                '_'
            ),
            months: function (momentToFormat, format) {
                if (!momentToFormat) {
                    return this._monthsNominativeEl;
                } else if (
                    typeof format === 'string' &&
                    /D/.test(format.substring(0, format.indexOf('MMMM')))
                ) {
                    // if there is a day number before 'MMMM'
                    return this._monthsGenitiveEl[momentToFormat.month()];
                } else {
                    return this._monthsNominativeEl[momentToFormat.month()];
                }
            },
            monthsShort: 'Ιαν_Φεβ_Μαρ_Απρ_Μαϊ_Ιουν_Ιουλ_Αυγ_Σεπ_Οκτ_Νοε_Δεκ'.split('_'),
            weekdays: 'Κυριακή_Δευτέρα_Τρίτη_Τετάρτη_Πέμπτη_Παρασκευή_Σάββατο'.split(
                '_'
            ),
            weekdaysShort: 'Κυρ_Δευ_Τρι_Τετ_Πεμ_Παρ_Σαβ'.split('_'),
            weekdaysMin: 'Κυ_Δε_Τρ_Τε_Πε_Πα_Σα'.split('_'),
            meridiem: function (hours, minutes, isLower) {
                if (hours > 11) {
                    return isLower ? 'μμ' : 'ΜΜ';
                } else {
                    return isLower ? 'πμ' : 'ΠΜ';
                }
            },
            isPM: function (input) {
                return (input + '').toLowerCase()[0] === 'μ';
            },
            meridiemParse: /[ΠΜ]\.?Μ?\.?/i,
            longDateFormat: {
                LT: 'h:mm A',
                LTS: 'h:mm:ss A',
                L: 'DD/MM/YYYY',
                LL: 'D MMMM YYYY',
                LLL: 'D MMMM YYYY h:mm A',
                LLLL: 'dddd, D MMMM YYYY h:mm A',
            },
            calendarEl: {
                sameDay: '[Σήμερα {}] LT',
                nextDay: '[Αύριο {}] LT',
                nextWeek: 'dddd [{}] LT',
                lastDay: '[Χθες {}] LT',
                lastWeek: function () {
                    switch (this.day()) {
                        case 6:
                            return '[το προηγούμενο] dddd [{}] LT';
                        default:
                            return '[την προηγούμενη] dddd [{}] LT';
                    }
                },
                sameElse: 'L',
            },
            calendar: function (key, mom) {
                var output = this._calendarEl[key],
                    hours = mom && mom.hours();
                if (isFunction(output)) {
                    output = output.apply(mom);
                }
                return output.replace('{}', hours % 12 === 1 ? 'στη' : 'στις');
            },
            relativeTime: {
                future: 'σε %s',
                past: '%s πριν',
                s: 'λίγα δευτερόλεπτα',
                ss: '%d δευτερόλεπτα',
                m: 'ένα λεπτό',
                mm: '%d λεπτά',
                h: 'μία ώρα',
                hh: '%d ώρες',
                d: 'μία μέρα',
                dd: '%d μέρες',
                M: 'ένα μήνα',
                MM: '%d μήνες',
                y: 'ένα χρόνο',
                yy: '%d χρόνια',
            },
            dayOfMonthOrdinalParse: /\d{1,2}η/,
            ordinal: '%dη',
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4, // The week that contains Jan 4st is the first week of the year.
            },
        });
        moment.locale("el");
        $rootScope.debug         = true;
        Date.prototype.addDays   = function (days) {
            var dat = new Date(this.valueOf());
            dat.setDate(dat.getDate() + days);
            return dat;
        };
        Date.prototype.present   = function (forPhp) {
            var month = this.getMonth() + 1;
            var day   = this.getDate();
            if (forPhp) {
                return this.getFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
            }
            return (day < 10 ? "0" + day : day) + "/" + (month < 10 ? "0" + month : month) + "/" + this.getFullYear();
        };
        Date.prototype.checkDate = function (date) {
            return this.present() === date;
        };

        alertify.defaults.transition = "fade";
        alertify.defaults.glossary   = {
            // dialogs default title
            title : 'Επιβεβαίωση',
            ok    : "OK",
            cancel: "Άκυρο"
        };
        alertify.defaults.theme      = {
            input : "form-control",
            ok    : "btn btn-default",
            cancel: "btn btn-secondary"
        };

        $rootScope.loginState = "login";
        $rootScope.mainState  = "admin.home";

        $rootScope.searchBox = {
            search  : {
                $: ""
            },
            submit  : angular.noop(),
            link    : function (pagination) {
                this.search = pagination.controls.search;
                if (pagination.fn) {
                    this.submit = pagination.fn;
                } else {
                    this.submit = angular.noop;
                }
            },
            clear   : function () {
                this.search.$ = "";
            },
            show    : function () {
                $('.search-box').fadeIn(function () {
                    $('#app-search').focus();
                });
            },
            hide    : function () {
                var parent = this;
                var before = angular.copy(this.search.$);
                $('.search-box').fadeOut(function () {
                    $rootScope.$applyAsync(function () {
                        parent.clear();
                        before && parent.submit();
                    })
                });
            },
            keypress: function (ev) {
                if (ev.key === "Escape") {
                    this.hide();
                }
            }
        };

        document.addEventListener("keydown", function (event) {
            if (event.isComposing) {
                return;
            }
            if (event.key === "f" && event.ctrlKey) {
                event.preventDefault();
                $rootScope.searchBox.show();
            }
        });
        var storage = {
            auth               : {
                id: 0
            },
            token              : "",
            notifications      : [],
            expiring: {
                from: 15,
                to: 30,
            },
            beforeinstallprompt: undefined
        };
        $localStorage.$default(angular.copy(storage));

        $state.defaultErrorHandler(function (error) {
            if (error.detail === "AUTH_REQUIRED") {
                return $state.go($rootScope.loginState);
            }
            if (error.detail === "UNAUTHORIZED") {
                return $state.go($rootScope.mainState);
            }
        });
        $rootScope.$on("Auth", function (ev, data) {
            var command     = data[0];
            var commandArgs = data[1];
            Auth[command].apply(Auth, commandArgs);
            if (command === "signOut") {
                $state.go($rootScope.loginState);
            }
        });

        var messaging;

        $rootScope.serviceWorker = {
            registration       : null,
            messaging          : null,
            showRss            : false,
            setRegistration    : function (registration) {
                var parent        = this;
                this.registration = registration;
                if (!this.messaging) {
                    this.messaging = firebase.messaging();
                    this.messaging.usePublicVapidKey("BH_4kA_uCVvo_sNtu3E4JMvPBtFxcZ_mYeZN4qZpGj0ycKLi-eSfW-zb2G2Z9stD5SirRpne1MMlnBFRJA4eQUc");
                    this.messaging.useServiceWorker(registration);
                    this.messaging.onTokenRefresh(function () {
                        parent.getToken();
                    });
                    this.messaging.onMessage(function (payload) {
                        $rootScope.$applyAsync(function () {
                            parent.onMessage(payload);
                        });
                    });
                }
                return this.checkPermission();
            },
            checkPermission    : function (notify) {
                if (!this.registration) {
                    return false;
                }
                return this.onPermission(Notification.permission, notify);
            },
            requestPermission  : function () {
                var parent = this;
                return this.messaging.requestPermission()
                .catch(function (err) {
                    console.error(err);
                })
                .then(function () {
                    return parent.checkPermission(true)
                })
            },
            onPermission       : function (state, notify) {
                if (state === "granted") {
                    return this.getToken(notify);
                }
                this.showRss = state === "default" || state === "prompt";
                return false;
            },
            getToken           : function (notify) {
                var parent = this;
                return this.messaging.getToken()
                .then(function (token) {
                    return parent.onToken(token, notify)
                })
                .catch(function (e) {
                    console.error(e);
                    return false;
                })
                .then(function (result) {
                    $rootScope.$applyAsync(function () {
                        parent.showRss = !result;
                    })
                });
            },
            onToken            : function (token, notify) {
                if (!token) {
                    return false;
                }
                return Auth.setToken(token, notify);
            },
            subscribe          : function () {
                // ! Deprecated
                if (!this.showRss) {
                    return false;
                }
                return this.registration.pushManager.subscribe({
                    userVisibleOnly     : true,
                    applicationServerKey: "BGJctxk6nMmM3Wlthborb0JfHD9927C7UZjmtamfX8OQX-OOMqLiL3F0SFlTOL_XigkEwzdax94eHV1kZmDruyw"
                })
                .then(function (sub) {
                    console.log(sub);
                    return !!sub;
                })
                .catch(function (e) {
                    if (Notification.permission === 'denied') {
                        console.error('Permission for notifications was denied..');
                    } else {
                        console.error('Unable to subscribe to push', e);
                    }
                    return false;
                });
            },
            hasUpdate          : false,
            updateAvailable    : function (state) {
                $rootScope.serviceWorker.hasUpdate = !!state;
            },
            update             : function () {
                location.reload();
            },
            notifications      : $localStorage.notifications,
            uuid               : function () {
                return `${moment().format()}-${Math.random()}`;
            },
            notify             : function (message, type) {
                var title = "Ενημέρωση";
                switch (type) {
                    case "success":
                        title = "Επιτυχία!";
                        break;
                    case "error":
                        title = "Σφάλμα!";
                        break;
                    case "warning":
                        title = "Προσοχή!";
                        break;
                    case "info":
                    default:
                        type = "info";
                        break;
                }
                var notification = {
                    type : type,
                    title: title,
                    body : message
                };
                this.onMessage({notification: notification});
            },
            dismiss            : function (notification) {
                notification.visible = false;
            },
            remove             : function (notification) {
                var index = this.notifications.map(function (n) {
                    return n.id;
                }).indexOf(notification.id);
                if (index >= 0) {
                    this.notifications.splice(index, 1);
                }
            },
            removeAll          : function () {
                var parent = this;
                alertify.confirm("Είστε σίγουρος/η?", function () {
                    $rootScope.$applyAsync(function () {
                        parent.notifications        = [];
                        $localStorage.notifications = [];
                    })
                });
            },
            beforeinstallprompt: false,
            installDismissed   : function () {
                this.beforeinstallprompt          = false;
                $localStorage.beforeinstallprompt = moment().format();
            },
            installClick       : function () {
                var parent = this;
                this.beforeinstallprompt.prompt();
                this.beforeinstallprompt.userChoice.then(function (choiceResult) {
                    // console.log('User ' + choiceResult.outcome +' home screen install');
                    $rootScope.$applyAsync(function () {
                        parent.beforeinstallprompt        = false;
                        $localStorage.beforeinstallprompt = moment().format();
                    })
                });
            },
            welcome            : function () {
                var notification = {
                    title: "Καλώς ήρθες!",
                    body : "Το περιεχόμενο είναι διαθέσιμο offline!"
                };
                this.onMessage({notification: notification});
            },
            onMessage          : function (payload) {
                // console.log(payload);
                if (!payload.notification && !payload.data) {
                    return;
                }
                if (payload.data && payload.data.refresh) {
                    $rootScope.serviceWorker.updateAvailable();
                    return;
                }
                var notification = payload.notification || payload.data;
                this.notifications.push({
                    id     : this.uuid(),
                    type   : notification.type || notification.tag || "info",
                    title  : notification.title,
                    image  : notification.image || "/assets/img/logo.png",
                    body   : notification.body,
                    visible: true,
                    date   : moment().toDate(),
                    actions: notification.actions || []
                });
            }
        };

        $rootScope.jQueryReady  = jQueryReady;
        $rootScope.sideBarReady = sideBarReady;
        $rootScope.normalize    = function (str) {
            var defaultDiacriticsRemovalMap = [
                {
                    'base'   : 'A',
                    'letters': /[ΑΆ\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g
                },
                {'base': 'AA', 'letters': /[\uA732]/g},
                {'base': 'AE', 'letters': /[\u00C6\u01FC\u01E2]/g},
                {'base': 'AO', 'letters': /[\uA734]/g},
                {'base': 'AU', 'letters': /[\uA736]/g},
                {'base': 'AV', 'letters': /[\uA738\uA73A]/g},
                {'base': 'AY', 'letters': /[\uA73C]/g},
                {'base': 'B', 'letters': /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
                {'base': 'C', 'letters': /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
                {
                    'base'   : 'D',
                    'letters': /[Δ\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g
                },
                {'base': 'DZ', 'letters': /[\u01F1\u01C4]/g},
                {'base': 'Dz', 'letters': /[\u01F2\u01C5]/g},
                {
                    'base'   : 'E',
                    'letters': /[ΕΈ\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g
                },
                {'base': 'F', 'letters': /[Φ\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
                {
                    'base'   : 'G',
                    'letters': /[Γ\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g
                },
                {
                    'base'   : 'H',
                    'letters': /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g
                },
                {
                    'base'   : 'I',
                    'letters': /[ΫΙΊΪΗΉ\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g
                },
                {'base': 'J', 'letters': /[\u004A\u24BF\uFF2A\u0134\u0248]/g},
                {
                    'base'   : 'K',
                    'letters': /[Κ\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g
                },
                {
                    'base'   : 'L',
                    'letters': /[Λ\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g
                },
                {'base': 'LJ', 'letters': /[\u01C7]/g},
                {'base': 'Lj', 'letters': /[\u01C8]/g},
                {'base': 'M', 'letters': /[Μ\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
                {
                    'base'   : 'N',
                    'letters': /[Ν\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g
                },
                {'base': 'NJ', 'letters': /[\u01CA]/g},
                {'base': 'Nj', 'letters': /[\u01CB]/g},
                {
                    'base'   : 'O',
                    'letters': /[ΩΏΟΌ\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g
                },
                {'base': 'OI', 'letters': /[\u01A2]/g},
                {'base': 'OO', 'letters': /[\uA74E]/g},
                {'base': 'OU', 'letters': /[\u0222]/g},
                {'base': 'P', 'letters': /[Π\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
                {'base': 'Q', 'letters': /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
                {
                    'base'   : 'R',
                    'letters': /[Ρ\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g
                },
                {
                    'base'   : 'S',
                    'letters': /[Σ\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g
                },
                {
                    'base'   : 'T',
                    'letters': /[Τ\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g
                },
                {'base': 'TZ', 'letters': /[\uA728]/g},
                {'base': 'TH', 'letters': /Θ/g},
                {'base': 'KS', 'letters': /Ξ/g},
                {'base': 'PS', 'letters': /Ψ/g},
                {
                    'base'   : 'U',
                    'letters': /[ΎΥ\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g
                },
                {'base': 'V', 'letters': /[Β\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
                {'base': 'VY', 'letters': /[\uA760]/g},
                {'base': 'W', 'letters': /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
                {'base': 'X', 'letters': /[Χ\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
                {
                    'base'   : 'Y',
                    'letters': /[Υ\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g
                },
                {
                    'base'   : 'Z',
                    'letters': /[Ζ\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g
                },
                {
                    'base'   : 'a',
                    'letters': /[αά\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g
                },
                {'base': 'aa', 'letters': /[\uA733]/g},
                {'base': 'ae', 'letters': /[\u00E6\u01FD\u01E3]/g},
                {'base': 'ao', 'letters': /[\uA735]/g},
                {'base': 'au', 'letters': /[\uA737]/g},
                {'base': 'av', 'letters': /[\uA739\uA73B]/g},
                {'base': 'ay', 'letters': /[\uA73D]/g},
                {'base': 'b', 'letters': /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
                {
                    'base'   : 'c',
                    'letters': /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g
                },
                {
                    'base'   : 'd',
                    'letters': /[δ\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g
                },
                {'base': 'dz', 'letters': /[\u01F3\u01C6]/g},
                {
                    'base'   : 'e',
                    'letters': /[εέ\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g
                },
                {'base': 'f', 'letters': /[φ\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
                {
                    'base'   : 'g',
                    'letters': /[γ\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g
                },
                {
                    'base'   : 'h',
                    'letters': /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g
                },
                {'base': 'hv', 'letters': /[\u0195]/g},
                {
                    'base'   : 'i',
                    'letters': /[ύυϋιϊίΐηή\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g
                },
                {'base': 'j', 'letters': /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
                {
                    'base'   : 'k',
                    'letters': /[κ\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g
                },
                {
                    'base'   : 'l',
                    'letters': /[λ\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g
                },
                {'base': 'lj', 'letters': /[\u01C9]/g},
                {'base': 'm', 'letters': /[μ\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
                {
                    'base'   : 'n',
                    'letters': /[ν\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g
                },
                {'base': 'nj', 'letters': /[\u01CC]/g},
                {
                    'base'   : 'o',
                    'letters': /[οόωώ\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g
                },
                {'base': 'oi', 'letters': /[\u01A3]/g},
                {'base': 'ou', 'letters': /[\u0223]/g},
                {'base': 'oo', 'letters': /[\uA74F]/g},
                {'base': 'p', 'letters': /[π\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
                {'base': 'q', 'letters': /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
                {
                    'base'   : 'r',
                    'letters': /[ρ\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g
                },
                {
                    'base'   : 's',
                    'letters': /[σς\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g
                },
                {
                    'base'   : 't',
                    'letters': /[τ\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g
                },
                {'base': 'tz', 'letters': /[\uA729]/g},
                {'base': 'th', 'letters': /θ/g},
                {'base': 'ks', 'letters': /ξ/g},
                {'base': 'ps', 'letters': /ψ/g},
                {
                    'base'   : 'u',
                    'letters': /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g
                },
                {'base': 'v', 'letters': /[β\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
                {'base': 'vy', 'letters': /[\uA761]/g},
                {'base': 'w', 'letters': /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
                {'base': 'x', 'letters': /[χ\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
                {
                    'base'   : 'y',
                    'letters': /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g
                },
                {
                    'base'   : 'z',
                    'letters': /[ζ\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g
                }
            ];

            for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
                str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
            }

            return str;
        };

        if ('serviceWorker' in navigator) {

            var config = {
                apiKey           : "AIzaSyAXJeog9Zav2zg6VIVazIazMMbX3XFmlPk",
                authDomain       : "ignatisd-1e430.firebaseapp.com",
                databaseURL      : "https://ignatisd-1e430.firebaseio.com",
                projectId        : "ignatisd-1e430",
                storageBucket    : "ignatisd-1e430.appspot.com",
                messagingSenderId: "291306326277",
                appId            : "1:291306326277:web:03e2b527c77c98b03dcf6c"
            };
            firebase.initializeApp(config);

            window.addEventListener('load', function () {
                navigator.serviceWorker.register('serviceworker.js').then(function (reg) {

                    if (reg.active) {
                        $rootScope.$applyAsync(function () {
                            // console.log("SW State: ", reg.active.state);
                            $rootScope.serviceWorker.setRegistration(reg);
                        });
                    }

                    // navigator.serviceWorker.addEventListener("message", $rootScope.serviceWorker.onMessage.bind($rootScope.serviceWorker));
                    reg.onupdatefound = function () {
                        const installingWorker         = this.installing;
                        installingWorker.onstatechange = function () {
                            switch (installingWorker.state) {
                                case 'installed':
                                    $rootScope.$applyAsync(function () {
                                        if (navigator.serviceWorker.controller) {
                                            $rootScope.serviceWorker.updateAvailable(true);
                                        } else {
                                            $rootScope.serviceWorker.welcome();
                                            $rootScope.serviceWorker.setRegistration(reg);
                                        }
                                    });
                                    break;
                                case 'redundant':
                                    console.debug('The installing service worker became redundant.');
                                    break;
                            }
                        };
                    };
                }).catch(function (e) {
                    console.error('Error during service worker registration:', e);
                });
            });
            window.addEventListener('beforeinstallprompt', function (e) {
                if (!$localStorage.beforeinstallprompt) {
                    $rootScope.$applyAsync(function () {
                        $rootScope.serviceWorker.beforeinstallprompt = e;
                    })
                }
                e.preventDefault();
                return false;
            });
        }
    }

    config.$inject = ['$httpProvider', '$stateProvider', '$urlRouterProvider', '$ocLazyLoadProvider', '$locationProvider', 'paramEncode'];

    function config($httpProvider, $stateProvider, $urlRouterProvider, $ocLazyLoadProvider, $locationProvider, paramEncode) {
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
        // $httpProvider.defaults.transformRequest = [function(data) {
        //     return angular.isObject(data) && String(data) !== '[object File]' ? paramEncode(data) : data;
        // }];
        $httpProvider.interceptors.push('HttpInterceptor');
        $locationProvider.html5Mode(true);
        $ocLazyLoadProvider.config({
            // debug: true,
            modules: [
                {
                    name : 'authModule',
                    files: [
                        'assets/js/controllers/auth.controller.min.js'
                    ]
                }
            ]
        });

        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get('$state');
            var Auth = $injector.get('Auth');
            if (Auth.isAdmin()) {
                $state.go("admin.404");
            } else {
                $state.go("home.404");
            }
        });

        $stateProvider
        .state('login', {
            url         : '/login',
            controllerAs: 'Login',
            controller  : 'AuthController',
            templateUrl : 'partials/login.html',
            resolve     : {
                // Lazy Load login
                loadMyModule : ['$ocLazyLoad', function ($ocLazyLoad) {
                    // you can lazy load files for an existing module
                    return $ocLazyLoad.load('authModule');
                }],
                // controller will not be loaded until $requireSignIn resolves
                // Auth refers to our $firebaseAuth wrapper in the factory below
                "currentAuth": ["Auth", function (Auth) {
                    // $requireSignIn returns a promise so the resolve waits for it to complete
                    // If the promise is rejected, it will throw a $routeChangeError (see above)
                    return Auth.$waitForSignIn();
                }]
            },
            onEnter     : function () {
                document.title = 'Office Admin | Είσοδος';
            }
        });

        $stateProvider
        .state('home', {
            abstract    : true,
            controllerAs: 'Home',
            controller  : 'HomeController',
            template    : '<div class="main-page" ui-view></div>'
        })
        .state('home.public', {
            url         : '/',
            templateUrl : 'partials/public.html',
            module      : 'home.public',
            onEnter     : function () {
                document.title = 'Μητρώο Αποβλήτων';
            }
        })
        .state('home.404', {
            url        : '/404',
            templateUrl: 'partials/404.html',
            module     : 'home.404',
            onEnter    : function () {
                document.title = '404 Not Found';
            }
        })
        .state('admin', {
            abstract    : true,
            controllerAs: 'Admin',
            controller  : 'AdminController',
            templateUrl : 'partials/admin.html',
            resolve     : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireSignIn();
                }]
            }
        })
        .state('admin.home', {
            url        : '/admin',
            templateUrl: 'partials/admin/home.html',
            module     : 'admin.home',
            controller : 'HomeController',
            onEnter    : function () {
                document.title = 'Office Admin';
            }
        })
        .state('admin.logs', {
            url        : '/admin/logs',
            templateUrl: 'partials/admin/logs.html',
            module     : 'admin.logs',
            controller : 'LogsController',
            onEnter    : function () {
                document.title = 'Office Admin | Logs';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.customers', {
            url        : '/admin/customers',
            module     : 'admin.customers',
            controller : 'CustomersController',
            templateUrl: 'partials/admin/customers.html',
            onEnter    : function () {
                document.title = 'Office Admin | Πελάτες';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }],
                "CustomerModel": ["Customer", function (Customer) {
                    return Customer;
                }],
            }
        })
        .state('admin.customersChris', {
            url        : '/admin/customers-chris',
            module     : 'admin.customersChris',
            controller : 'CustomersController',
            templateUrl: 'partials/admin/customers.html',
            onEnter    : function () {
                document.title = 'Office Admin | Πελάτες Χρήστου';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }],
                "CustomerModel": ["CustomerChris", function (CustomerChris) {
                    return CustomerChris;
                }],
            }
        })
        .state('admin.protocols', {
            url        : '/admin/protocols',
            module     : 'admin.protocols',
            controller : 'ProtocolsController',
            templateUrl: 'partials/admin/protocols.html',
            onEnter    : function () {
                document.title = 'Office Admin | Πρωτόκολλο';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }],
                "singleProtocol": function () {
                    return null;
                }
            }
        })
        .state('admin.protocol', {
            url        : '/admin/protocol/:protocol',
            module     : 'admin.protocols',
            controller : 'ProtocolsController',
            templateUrl: 'partials/admin/protocols.html',
            onEnter    : function () {
                document.title = 'Office Admin | Πρωτόκολλο';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }],
                "singleProtocol": ["$stateParams", function ($stateParams) {
                    return $stateParams.protocol;
                }]
            }
        })
        .state('admin.contacts', {
            url        : '/admin/contacts',
            module     : 'admin.contacts',
            controller : 'ContactsController',
            templateUrl: 'partials/admin/contacts.html',
            onEnter    : function () {
                document.title = 'Office Admin | Επαφές';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.services', {
            url        : '/admin/services',
            module     : 'admin.services',
            controller : 'ServicesController',
            templateUrl: 'partials/admin/services.html',
            onEnter    : function () {
                document.title = 'Office Admin | Υπηρεσίες';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.activities', {
            url        : '/admin/activities',
            module     : 'admin.activities',
            controller : 'ActivitiesController',
            templateUrl: 'partials/admin/activities.html',
            onEnter    : function () {
                document.title = 'Office Admin | Δραστηριότητες';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.studies', {
            url        : '/admin/studies',
            module     : 'admin.studies',
            controller : 'StudiesController',
            templateUrl: 'partials/admin/studies.html',
            onEnter    : function () {
                document.title = 'Office Admin | Μελέτες';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.municipalities', {
            url        : '/admin/municipalities',
            module     : 'admin.municipalities',
            controller : 'MunicipalitiesController',
            templateUrl: 'partials/admin/municipalities.html',
            onEnter    : function () {
                document.title = 'Office Admin | Δήμοι';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.doys', {
            url        : '/admin/doys',
            module     : 'admin.doys',
            controller : 'DoysController',
            templateUrl: 'partials/admin/doys.html',
            onEnter    : function () {
                document.title = 'Office Admin | Δ.Ο.Υ.';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.users', {
            url        : '/admin/users',
            module     : 'admin.users',
            controller : 'UsersController',
            templateUrl: 'partials/admin/users.html',
            onEnter    : function () {
                document.title = 'Office Admin | Χρήστες';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.afm', {
            url        : '/admin/afm',
            module     : 'admin.afm',
            controller : 'AfmController',
            templateUrl: 'partials/admin/afm.html',
            onEnter    : function () {
                document.title = 'Office Admin | Α.Φ.Μ.';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.templates', {
            url        : '/admin/templates',
            module     : 'admin.templates',
            controller : 'TemplateController',
            templateUrl: 'partials/admin/templates.html',
            onEnter    : function () {
                document.title = 'Office Admin | Word Template';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.fem', {
            url        : '/admin/fem',
            module     : 'admin.fem',
            controller : 'FemController',
            templateUrl: 'partials/admin/fem.html',
            onEnter    : function () {
                document.title = 'Office Admin | Φ.Ε.Μ.';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.tasks', {
            url        : '/admin/tasks',
            module     : 'admin.tasks',
            controller : 'TasksController',
            templateUrl: 'partials/admin/tasks.html',
            onEnter    : function () {
                document.title = 'Office Admin | Πολεοδομία';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.taskStudies', {
            url        : '/admin/task-studies',
            module     : 'admin.taskStudies',
            controller : 'TaskStudiesController',
            templateUrl: 'partials/admin/taskStudies.html',
            onEnter    : function () {
                document.title = 'Office Admin | Μελέτες Πολεοδομίας';
            },
            resolve    : {
                "currentAuth": ["Auth", function (Auth) {
                    return Auth.$requireAdmin();
                }]
            }
        })
        .state('admin.404', {
            url        : '/admin/404',
            templateUrl: 'partials/404.html',
            module     : 'admin.404',
            onEnter    : function () {
                document.title = 'Office Admin | 404 Not Found';
            }
        });
    }

    function sideBarReady() {

        // ------------------------------------------------------- //
        // Sidebar Functionality
        // ------------------------------------------------------ //
        $('#toggle-btn').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');

            $('.side-navbar').toggleClass('shrinked');
            $('.content-inner').toggleClass('active');

            if ($(window).outerWidth() >= 1183) {
                if ($(this).hasClass('active')) {
                    $('.navbar-header .brand-small').hide();
                    $('.navbar-header .brand-big').show();
                } else {
                    $('.navbar-header .brand-small').show();
                    $('.navbar-header .brand-big').hide();
                }
            }

            if ($(window).outerWidth() < 1183) {
                $('.navbar-header .brand-small').show();
            }
        });

    }

    function jQueryReady() {
        $(document).ready(function () {
            $("#mask").fadeOut("slow");
            var body = $('body');
            body.removeClass("login-page").addClass("home-page");

            // ------------------------------------------------------- //
            // Card Close
            // ------------------------------------------------------ //
            body.on('click', '.card-close a.remove', function (e) {
                e.preventDefault();
                $(this).parents('.card').fadeOut();
            });

            // ------------------------------------------------------- //
            // Adding fade effect to dropdowns
            // ------------------------------------------------------ //
            body.on('show.bs.dropdown', '.dropdown', function () {
                $(this).find('.dropdown-menu').first().stop(true, true).fadeIn();
            });
            body.on('hide.bs.dropdown', '.dropdown', function () {
                $(this).find('.dropdown-menu').first().stop(true, true).fadeOut();
            });

            // ------------------------------------------------------- //
            // Transition Placeholders
            // ------------------------------------------------------ //
            $('input.input-material').on('focus', function () {
                $(this).siblings('.label-material').addClass('active');
            });

            $('input.input-material').on('blur', function () {
                $(this).siblings('.label-material').removeClass('active');

                if ($(this).val() !== '') {
                    $(this).siblings('.label-material').addClass('active');
                } else {
                    $(this).siblings('.label-material').removeClass('active');
                }
            });
        });
    }

})();

