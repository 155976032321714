(function () {
    'use strict';
    angular
    .module("app")
    .controller('AfmController', AfmController);

    AfmController.$inject = ['$scope', '$rootScope', '$http', 'ModelController', 'Contact', 'Customer'];

    function AfmController($scope, $rootScope, $http, ModelController, Contact, Customer) {
        $scope.form = {
            afm: "",
            business: null,
            contact: null,
            customer: null,
            reset: function () {
                this.afm = "";
                this.business = null;
                this.contact = null;
                this.customer = null;
                this.loading = false;
            },
            search: function () {
                if (!this.afm) {
                    return;
                }
                var parent = this;
                var config = {
                    method: "GET",
                    url: "/api/afm/" + this.afm
                };
                $http(config)
                .then(function (response) {
                    if (!response.data.success) {
                        alertify.error(response.data.reason);
                        return;
                    }
                    parent.business = response.data.business;
                    parent.contact = response.data.contact;
                    parent.customer = response.data.customer;
                })
                .catch(function (err) {
                    console.warn(err);
                    alertify.error("Προέκυψε κάποιο σφάλμα επικοινωνίας με τον Server");
                })
            },
            loading: false,
            createContact: function () {
                if (!this.contact) {
                    return;
                }
                var parent = this;
                this.loading = true;
                var contact = angular.copy(this.contact);
                var ctrl = new ModelController(Contact, {});
                ctrl.create(contact).then(function(c) {
                    if (c) {
                        parent.contact = c;
                    }
                    parent.loading = false;
                }).catch(function (e) {
                    console.warn(e);
                    parent.loading = false;
                });
            },
            createCustomer: function () {
                if (!this.customer) {
                    return;
                }
                var parent = this;
                this.loading = true;
                var customer = angular.copy(this.customer);
                var ctrl = new ModelController(Customer, {});
                ctrl.create(customer).then(function(c) {
                    if (c) {
                        parent.customer = c;
                    }
                    parent.loading = false;
                }).catch(function (e) {
                    console.warn(e);
                    parent.loading = false;
                });
            }
        }
    }
})();
