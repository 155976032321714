(function () {
    'use strict';
    angular
    .module("app")
    .controller('FemController', FemController);

    FemController.$inject = ['$scope', '$rootScope', 'TypeAhead', 'ModelController', 'Modal', 'Fem', 'Contact'];

    function FemController($scope, $rootScope, TypeAhead, ModelController, Modal, Fem, Contact) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;

        var options = {propertyName: "id", reverse: true, quantity: 50, relations: "engineer partner employer contractor"};
        var defaultFem = {
            "engineer_percent": "",
            "apy": "",
            "apy_date": "",
            "engineer_id": null,
            "engineer_name": "",
            "employer_id": null,
            "employer_name": "",
            "employer_address": "",
            "employer_afm": "",
            "employer_doy": "",
            "contractor_id": null,
            "contractor_name": "",
            "task_value": "",
            "task_reward": "",
            "supervision_reward": "",
            "task_tax": "",
            "supervision_tax": "",
            "total_tax": "",
            "task_address": "",
            "description": "",
            "apartments_m2": "",
            "prof_space_m2": "",
            "other_m2": "",
            "task_doy": "",
            "start_year": "",
            "now_date": "",
            "remarks": ""
        };
        var settings = {
            defaultModel: defaultFem,
            dates: [
                "apy_date",
                "now_date",
                "start_year",
            ]
        };
        $scope.modal = new Modal();


        $scope.Fem = new ModelController(Fem, options, settings);
        $rootScope.searchBox.link($scope.Fem.list);


        var searchOptions         = {propertyName: "id", reverse: true, quantity: 50};

        $scope.Contacts = new ModelController(Contact, searchOptions);
        $scope.Contacts.onSelectEngineer = function(item) {
            item = item || {};
            $scope.Fem.modal.set("engineer_name", item.name);
            $scope.Fem.modal.set("engineer", item);
        }
        $scope.Contacts.onSetEngineer = function($select) {
            delete $scope.Fem.modal.model.engineer;
            $scope.Fem.modal.model.engineer_id = null;
            $scope.Fem.modal.set("engineer_name", $select.search);
            $select.search = "";
            $select.open = false;
        }
        $scope.Contacts.onResetEngineer = function() {
            $scope.Fem.modal.model.engineer_id = null;
            $scope.Fem.modal.model.engineer_name = ''
            delete $scope.Fem.modal.model.engineer;
        };

        $scope.Contacts.onSelectEmployer = function(item) {
            item = item || {};
            $scope.Fem.modal.set("employer_name", item.name);
            $scope.Fem.modal.set("employer_address", item.address);
            $scope.Fem.modal.set("employer_afm", item.afm);
            $scope.Fem.modal.set("employer_doy", item.doy);
            $scope.Fem.modal.set("employer", item);
        }
        $scope.Contacts.onSetEmployer = function($select) {
            delete $scope.Fem.modal.model.employer;
            $scope.Fem.modal.model.employer_id = null;
            $scope.Fem.modal.set("employer_name", $select.search);
            $scope.Fem.modal.model.employer_address = '';
            $scope.Fem.modal.model.employer_afm = '';
            $scope.Fem.modal.model.employer_doy = '';
            $select.search = "";
            $select.open = false;
        }
        $scope.Contacts.onResetEmployer = function() {
            $scope.Fem.modal.model.employer_id = null;
            $scope.Fem.modal.model.employer_name = '';
            $scope.Fem.modal.model.employer_address = '';
            $scope.Fem.modal.model.employer_afm = '';
            $scope.Fem.modal.model.employer_doy = '';
            delete $scope.Fem.modal.model.employer;
        };

        $scope.Contacts.onSelectContractor = function(item) {
            item = item || {};
            $scope.Fem.modal.set("contractor_name", item.name);
            $scope.Fem.modal.set("contractor", item);
        }
        $scope.Contacts.onSetContractor = function($select) {
            delete $scope.Fem.modal.model.contractor;
            $scope.Fem.modal.model.contractor_id = null;
            $scope.Fem.modal.set("contractor_name", $select.search);
            $select.search = "";
            $select.open = false;
        }
        $scope.Contacts.onResetContractor = function() {
            $scope.Fem.modal.model.contractor_id = null;
            $scope.Fem.modal.model.contractor_name = ''
            delete $scope.Fem.modal.model.contractor;
        };

        $scope.Contacts.onSelectPartner = function(item) {
            item = item || {};
            $scope.Fem.modal.set("partner_name", item.name);
            $scope.Fem.modal.set("partner", item);
        }
        $scope.Contacts.onSetPartner = function($select) {
            delete $scope.Fem.modal.model.partner;
            $scope.Fem.modal.model.partner_id = null;
            $scope.Fem.modal.set("partner_name", $select.search);
            $select.search = "";
            $select.open = false;
        }
        $scope.Contacts.onResetPartner = function() {
            $scope.Fem.modal.model.partner_id = null;
            $scope.Fem.modal.model.partner_name = ''
            delete $scope.Fem.modal.model.partner;
        };


        $scope.datepickerOptions = {
            format: 'dd/MM/yyyy',
            startingDay: 1,
            showWeeks: false
        }

        $scope.typeAhead = new TypeAhead($scope.Fem);
    }
})();
