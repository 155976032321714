(function () {
    'use strict';
    angular
    .module("app")
    .controller('CustomersController', CustomersController);

    CustomersController.$inject = ['$scope', '$rootScope', 'currentAuth', 'CustomerModel', 'ModelController', 'Modal', 'Activity', 'Contact', 'User'];

    function CustomersController($scope, $rootScope, currentAuth, CustomerModel, ModelController, Modal, Activity, Contact, User) {
        $scope.searchEnabled = $(window).outerHeight() >= 720;
        var options         = {propertyName: "id", reverse: true, quantity: 50, relations: "representative partner contacts admin"};
        var defaultCustomer = {
            "name"                   : "",
            "activity"               : null,
            "partner_name"           : null,
            "complete"               : 0,
            "phone"                  : null,
            "mobile"                 : null,
            "fax"                    : null,
            "email"                  : null,
            "address"                : null,
            "afm"                    : null,
            "doy"                    : null,
            "representative_name"    : null,
            "representative_identity": null,
            "comment"                : "",
            "admin_id"               : null,
        };
        var settings = {
            defaultModel: defaultCustomer,
        }
        $scope.Customers    = new ModelController(CustomerModel, options, settings);
        $rootScope.searchBox.link($scope.Customers.list);

        $scope.modal = new Modal();
        var searchOptions         = {propertyName: "id", reverse: true, quantity: 50};

        $scope.Activities = new ModelController(Activity, searchOptions);
        $scope.Activities.onSelect = function(item) {
            item = item || {};
            $scope.Customers.modal.set("activity", item.name);
        }
        $scope.Activities.onReset = function() {
            $scope.Customers.modal.model.activity_id = null;
            $scope.Customers.modal.model.activity = '';
        };

        $scope.Users = new ModelController(User, searchOptions);
        $scope.Users.onSelect = function(item) {
            item = item || {};
            $scope.Customers.modal.set("admin", item);
            $scope.Customers.modal.set("admin_name", item.name);
        }
        $scope.Users.onReset = function() {
            $scope.Customers.modal.model.admin_id = null;
            $scope.Customers.modal.model.admin_name = '';
            delete $scope.Customers.modal.model.admin;
        };

        $scope.Contacts = new ModelController(Contact, searchOptions);
        $scope.Contacts.onSelect = function(item) {
            item = item || {};
            $scope.Customers.modal.set("representative", item);
            $scope.Customers.modal.set("representative_name", item.name);
        }
        $scope.Contacts.onReset = function() {
            $scope.Customers.modal.model.representative_id = null;
            $scope.Customers.modal.model.representative_name = '';
            delete $scope.Customers.modal.model.representative;
        };

        $scope.Contacts.onSelectPartner = function(item) {
            item = item || {};
            $scope.Customers.modal.set("partner", item);
            $scope.Customers.modal.set("partner_name", item.name);
        }
        $scope.Contacts.onResetPartner = function() {
            $scope.Customers.modal.model.partner_id = null;
            $scope.Customers.modal.model.partner_name = '';
            delete $scope.Customers.modal.model.partner;
        };

        $scope.selectedContact = {};

        $scope.Contacts.onSelectContact = function (item) {
            $scope.selectedContact = item;
        }

        $scope.Contacts.onResetContact = function () {
            $scope.selectedContact = {};
        }

        $scope.Contacts.addContact = function (contact) {
            if (!$scope.Customers.modal.model.contacts) {
                $scope.Customers.modal.model.contacts = [];
            }
            if (contact) {
                $scope.Customers.modal.model.contacts.push(contact);
                $scope.selectedContact = {};
            }
        }
    }
})();
