(function () {
    'use strict';
    angular
        .module("app")
        .controller("TemplateController", TemplateController);

    TemplateController.$inject = ["$scope", "$http", "$q", "s2ab"];

    function TemplateController($scope, $http, $q, s2ab) {
        $scope.form = {
            loading: false,
            analyzed: false,
            file: undefined,
            base64: "",
            filename: "",
            variables: [],
            blocks: [],
            reset: function () {
                this.loading = false;
                this.analyzed = false;
                this.base64 = "";
                this.filename = "";
                this.variables = [];
                this.blocks = [];
                this.file = undefined;
                angular.element(document.getElementById("customFile")).val(null);
            },
            getFileBuffer: function (file) {
                var deferred = new $q.defer();
                var reader = new FileReader();
                reader.onloadend = function (e) {
                    deferred.resolve(e.target.result);
                };
                reader.onerror = function (e) {
                    deferred.reject(e.target.error);
                };
                reader.readAsDataURL(file);
                return deferred.promise;
            },
            analyze: function () {
                if (!this.file || this.loading) {
                    return;
                }
                this.loading = true;
                this.analyzed = false;
                var parent = this;
                this.getFileBuffer(this.file).then(function (result) {
                    var filename = parent.file.name;
                    parent.reset();
                    parent.base64 = result;
                    parent.filename = filename;
                    var config = {
                        method: "POST",
                        url: "/api/templates/variables",
                        data: {
                            template: result
                        },
                    };
                    return $http(config)
                        .then(function (response) {
                            parent.loading = false;
                            if (!response.data.success) {
                                alertify.error(response.data.reason);
                                return;
                            }
                            parent.variables = response.data.data.variables || [];
                            parent.blocks = response.data.data.blocks || [];
                            parent.analyzed = true;

                        });
                    })
                    .catch(function (err) {
                        parent.loading = false;
                        console.warn(err);
                        alertify.error("Προέκυψε κάποιο σφάλμα επικοινωνίας με τον Server");
                    })
            },
            process: function () {
                if (this.loading || !this.base64) {
                    return;
                }
                this.loading = true;
                var parent = this;
                var config = {
                    method: 'POST',
                    url: '/api/templates/process',
                    data: {
                        template: this.base64,
                        filename: this.filename,
                        variables: this.variables,
                        blocks: this.blocks
                    }
                };
                $http(config)
                    .then(function (response) {
                        parent.reset();
                        if (!response.data.success) {
                            alertify.error(response.data.reason);
                            return;
                        }
                        var bin = atob(response.data.base64);
                        var ab = s2ab(bin);
                        var blob = new Blob([ab], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;'});
                        saveAs(blob, response.data.filename);
                    })
                    .catch(function (err) {
                        parent.reset();
                        console.warn(err);
                        alertify.error("Προέκυψε κάποιο σφάλμα επικοινωνίας με τον Server");
                    });
            }
        }
    }
})();
